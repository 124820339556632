import useTranslation from 'next-translate/useTranslation'
import { StyledButton } from './NavButton'

interface ConfirmButton {
  onClick: () => void
  size: 'small' | 'medium' | 'large'
}

export const ConfirmButton = ({ onClick, size }: ConfirmButton) => {
  const { t } = useTranslation('common')
  return (
    <StyledButton size={size} action='primary' onClick={onClick}>
      {t('buttons.confirm')}
    </StyledButton>
  )
}
