import { Modal } from '@/Modal/Modal'
import { useStore } from '@stores/index'
import useTranslation from 'next-translate/useTranslation'
import router from 'next/router'
import React from 'react'

interface NewInflowCalculationModal {
  guest: boolean
}

export const NewInflowCalculationModal = ({ guest }: NewInflowCalculationModal) => {
  const { t } = useTranslation('common')
  const { formStore } = useStore()

  const newInflowCalculation = () => {
    formStore.createInflowCalculationForm()
    formStore.setNewCalculationModalIsOpen()
    router.push('/inflow-calculation/organisation-details/name')
  }

  return (
    <Modal
      confirm={() => newInflowCalculation()}
      close={formStore.setNewCalculationModalIsOpen}
      title={t('modal.new-calculation-confirmation-title')}
      text={t('modal.new-calculation-warning-1') + (guest ? t('modal.new-calculation-warning-guest') : '')}
    >{guest ?
      (<span><a href={process.env.NEXT_PUBLIC_WGV_EMPLOYER_MEETINSTRUMENTEN_URL!}>{t('modal.login')}</a>.</span>)
      :
      null
    }</Modal>
  )
}
