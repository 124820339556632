import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import * as https from 'https'
import { onError } from '@apollo/client/link/error'
import { toast } from 'react-hot-toast'
import * as Sentry from '@sentry/nextjs'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

const createApolloClient = () => {
  const gqlLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
    fetchOptions: {
      agent: new https.Agent({
        rejectUnauthorized: process.env.NODE_ENV === 'production',
      }),
    },
  })

  const persistedQueriesLink = createPersistedQueryLink({ sha256 })

  const authLink = setContext((_, { headers }) => {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('auth:token')
      return {
        headers: {
          ...headers,
          authorization: token && `Bearer ${token}`,
        },
      }
    }
  })

  const IGNORED_ERRORS = [
    'HC0020', // Persisted query not found
    'AUTH_NOT_AUTHENTICATED', // Handled separately
  ]

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        if (!IGNORED_ERRORS.includes(extensions?.code)) {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${path}`
          )
          Sentry.captureException({ message, locations, path, extensions })
          toast.error(message)
        }
      })
    }
    if (networkError) {
      console.error(`[Network error]: ${JSON.stringify(networkError)}`)
      toast.error(networkError.message)
    }

    if (
      graphQLErrors?.find(
        (e) => e.extensions?.code === 'AUTH_NOT_AUTHENTICATED'
      ) &&
      window?.location.href.indexOf('login-with-token') === -1
    ) {
      localStorage.removeItem('auth:token');
    }
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: errorLink
      .concat(authLink)
      .concat(persistedQueriesLink)
      .concat(gqlLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })
}

export const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return createApolloClient()
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = createApolloClient()
  }

  return apolloClient
}

export const useApollo = getApolloClient
