import { NewCalculationSideButton } from '@/Buttons/NewCalculationSideButton'
import { ResetButton } from '@/Buttons/ResetButton'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useStore } from '@stores/index'
import NavItem from './NavItem'
import { NavContainer, Sidenav, StepTitle } from './Sidenav'
import { observer } from 'mobx-react'
import { StepStatus } from '@stores/costStore'
import { PreviousCalculationButton } from '@/Buttons/PreviousCalculationButton'

export const icon = (status: StepStatus) => {
  switch (status) {
    case StepStatus.NONE:
      return 'none'
    case StepStatus.WARNING:
      return 'warning'
    case StepStatus.COMPLETED:
      return 'completed'
  }
}

const SidenavCostsCalculationInstrument = observer(() => {
  const router = useRouter()
  const { costStore } = useStore()
  const { t } = useTranslation('common')

  return (
    <Sidenav>
      <NavContainer>
        <StepTitle>{t('nav.basic-information-students')}</StepTitle>
        <NavItem
          router={router}
          path='/costs-calculation/basic-information/internship-duration'
          text={t('nav.duration-internship')}
          icon={icon(costStore.steps.averageInternshipWeeks())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/basic-information/hours-superformatief'
          text={t('nav.internship-hours-bovenformatief')}
          icon={icon(costStore.steps.totalExtraHoursPerWeek())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/basic-information/hours-payed-by-employer'
          text={t('nav.hours-payed-employer')}
          icon={icon(costStore.steps.totalPaidSchoolHoursPerWeek())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/basic-information/BBL-students'
          text={t('nav.basic-information-BBL')}
          icon={icon(costStore.steps.bblStudents())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/basic-information/BOL-students'
          text={t('nav.basic-information-BOL')}
          icon={icon(costStore.steps.bolStudents())}
        />
        <StepTitle>{t('nav.supervision')}</StepTitle>
        <NavItem
          router={router}
          path='/costs-calculation/supervision-per-student/bbl'
          text={t('nav.supervision-student-BBL')}
          icon={icon(costStore.steps.bblMeetingSchedules())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervision-per-student/bol'
          text={t('nav.supervision-student-BOL')}
          icon={icon(costStore.steps.bolMeetingSchedules())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervision-per-student/direct-care'
          text={t('nav.hours-direct-care')}
          icon={icon(costStore.steps.availableHoursPercentage())}
        />
        <StepTitle>{t('nav.supervisors-hours')}</StepTitle>
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bbl-introduction-meeting'
          text={t('nav.introduction-meeting-BBL')}
          icon={icon(costStore.steps.bblIntroductionMeeting())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bbl-planning-meeting'
          text={t('nav.planning-meeting-BBL')}
          icon={icon(costStore.steps.bblPlanningMeeting())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bbl-progress-meeting'
          text={t('nav.progress-meeting-BBL')}
          icon={icon(costStore.steps.bblProgressMeeting())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bbl-intermediate-evaluation'
          text={t('nav.intermediate-evaluation-BBL')}
          icon={icon(costStore.steps.bblIntermediateEvaluation())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bbl-end-evaluation'
          text={t('nav.end-evaluation-BBL')}
          icon={icon(costStore.steps.bblEndEvaluation())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bbl-indirect-hours-work-supervisor'
          text={t('nav.work-supervisor-BBL')}
          icon={icon(costStore.steps.bblWorkInstructorIndirectWork())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bbl-indirect-hours-practical-supervisor'
          text={t('nav.practical-supervisor-BBL')}
          icon={icon(costStore.steps.bblPracticeInstructorIndirectWork())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bol-introduction-meeting'
          text={t('nav.introduction-meeting-BOL')}
          icon={icon(costStore.steps.bolIntroductionMeeting())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bol-planning-meeting'
          text={t('nav.planning-meeting-BOL')}
          icon={icon(costStore.steps.bolPlanningMeeting())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bol-progress-meeting'
          text={t('nav.progress-meeting-BOL')}
          icon={icon(costStore.steps.bolProgressMeeting())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bol-intermediate-evaluation'
          text={t('nav.intermediate-evaluation-BOL')}
          icon={icon(costStore.steps.bolIntermediateEvaluation())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bol-end-evaluation'
          text={t('nav.end-evaluation-BOL')}
          icon={icon(costStore.steps.bolEndEvaluation())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bol-indirect-hours-work-supervisor'
          text={t('nav.work-supervisor-BOL')}
          icon={icon(costStore.steps.bolWorkInstructorIndirectWork())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/supervisors-hours/bol-indirect-hours-practical-supervisor'
          text={t('nav.practical-supervisor-BOL')}
          icon={icon(costStore.steps.bolPracticeInstructorIndirectWork())}
        />
        <StepTitle>{t('nav.costs-internship')}</StepTitle>
        <NavItem
          router={router}
          path='/costs-calculation/costs/work-supervisor/salary'
          text={t('nav.salary-work-supervisor')}
          icon={icon(costStore.steps.workInstructorAverageSalary())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/work-supervisor/social-costs'
          text={t('nav.social-costs-work-supervisor')}
          icon={icon(costStore.steps.workInstructorSocialSecurity())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/practice-supervisor/salary'
          text={t('nav.salary-practice-supervisor')}
          icon={icon(costStore.steps.practiceInstructorAverageSalary())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/practice-supervisor/social-costs'
          text={t('nav.social-costs-practice-supervisor')}
          icon={icon(costStore.steps.practiceInstructorSocialSecurity())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/bbl/yearly-costs'
          text={t('nav.education-costs-BBL')}
          icon={icon(costStore.steps.educationCosts())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/bbl/salary'
          text={t('nav.salary-BBL')}
          icon={icon(costStore.steps.bblAverageSalary())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/bbl/social-costs'
          text={t('nav.social-costs-BBL')}
          icon={icon(costStore.steps.bblSocialSecurity())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/bol/internship-compensation'
          text={t('nav.internship-compensation-BOL')}
          icon={icon(costStore.steps.bolHourlySalary())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/bol/social-costs'
          text={t('nav.social-other-costs-BOL')}
          icon={icon(costStore.steps.bolOtherCosts())}
        />
        <NavItem
          router={router}
          path='/costs-calculation/costs/bol/productivity'
          text={t('nav.productivity-BOL')}
          icon={icon(costStore.steps.bolEmployeeProductivity())}
        />
        <StepTitle>{t('nav.results-step')}</StepTitle>
        <NavItem router={router} path='/costs-calculation/results' text={t('nav.results')} icon='none' />
      </NavContainer>
      <PreviousCalculationButton />
      <ResetButton onClick={() => costStore.reset()}>{t('buttons.reset')}</ResetButton>
      <NewCalculationSideButton />
    </Sidenav>
  )
})

export default SidenavCostsCalculationInstrument
