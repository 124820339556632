export const WarningIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.9332 12.375L7.43321 1.125C7.33633 0.957813 7.16915 0.875 7.0004 0.875C6.83165 0.875 6.6629 0.957813 6.56758 1.125L0.0675834 12.375C-0.124604 12.7094 0.116021 13.125 0.500396 13.125H13.5004C13.8848 13.125 14.1254 12.7094 13.9332 12.375ZM6.5004 5.5C6.5004 5.43125 6.55665 5.375 6.6254 5.375H7.3754C7.44415 5.375 7.5004 5.43125 7.5004 5.5V8.375C7.5004 8.44375 7.44415 8.5 7.3754 8.5H6.6254C6.55665 8.5 6.5004 8.44375 6.5004 8.375V5.5ZM7.0004 11C6.80413 10.996 6.61726 10.9152 6.47988 10.775C6.3425 10.6348 6.26555 10.4463 6.26555 10.25C6.26555 10.0537 6.3425 9.86522 6.47988 9.725C6.61726 9.58478 6.80413 9.50401 7.0004 9.5C7.19666 9.50401 7.38353 9.58478 7.52091 9.725C7.6583 9.86522 7.73524 10.0537 7.73524 10.25C7.73524 10.4463 7.6583 10.6348 7.52091 10.775C7.38353 10.9152 7.19666 10.996 7.0004 11Z'
        fill='#FF9900'
      />
    </svg>
  )
}
