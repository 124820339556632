import { styled } from '@styles/config'
import { Header, SubHeader } from '@/Header/Header'
import { Logo } from '@/Logo/Logo'
import { LogoutButton } from '@/Buttons/LogoutButton'
import { ContentWrapper } from '@/ContentWrapper/ContentWrapper'
import { useRouter } from 'next/router'
import React from 'react'
import { observer } from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import SidenavInflowCalculationInstrument from '@/Sidenav/SidenavInflowCalculationInstrument'
import SidenavCostsCalculationInstrument from '@/Sidenav/SidenavCostsCalculationInstrument'
import { useStore } from '@stores/index'
import { NewCostsCalculationModal } from '@/Modal/NewCostsCalculationModal/NewCostsCalculationModal'
import { NewInflowCalculationModal } from '@/Modal/NewInflowCalculationModal/NewInflowCalculationModal'
import { NewCostsCalculationGuestModal } from '@/Modal/NewCostsCalculationGuestModal/NewCostsCalculationGuestModal'
import { NewInflowCalculationGuestModal } from '@/Modal/NewInflowCalculationGuestModal/NewInflowCalculationGuestModal'
import {
  ResumeCostsCalculationGuestModal
} from '@/Modal/ResumeCostsCalculationGuestModal/ResumeCostsCalculationGuestModal'
import {
  ResumeInflowCalculationGuestModal
} from '@/Modal/ResumeInflowCalculationGuestModal/ResumeInflowCalculationGuestModal'
import { MoveCostsCalculationModal } from '@/Modal/MoveCostsCalculationModal/MoveCostsCalculationModal'
import { MoveInflowCalculationModal } from '@/Modal/MoveInflowCalculationModal/MoveInflowCalculationModal'

const Grid = styled('div', {
  width: 'auto',
  height: 'auto',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '92px 180px 1fr',
  gridTemplateAreas: `'header'
        'subheader'
        'content'`,
})

export const Layout = observer(({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { formStore, authStore } = useStore()

  const logout = () => {
    authStore.removeToken()
    window.location.href = process.env.NEXT_PUBLIC_WGV_EMPLOYER_URL!
  }

  const login = () => {
    window.location.href = process.env.NEXT_PUBLIC_WGV_EMPLOYER_MEETINSTRUMENTEN_URL!
  }

  const openedModal = () => {
    const firstPathSegment = router.pathname.split('/')[1]
    if (firstPathSegment === 'costs-calculation') {
      if (formStore.moveCalculationModalIsOpen) {
        return <MoveCostsCalculationModal />
      } else if (formStore.newCalculationModalIsOpen) {
        return <NewCostsCalculationModal guest={!authStore.hasToken} />
      } else if (formStore.newCalculationModalGuestIsOpen) {
        return <NewCostsCalculationGuestModal />
      } else if (formStore.resumeCalculationModalGuestIsOpen) {
        return <ResumeCostsCalculationGuestModal />
      }
    } else if (firstPathSegment === 'inflow-calculation') {
      if (formStore.moveCalculationModalIsOpen) {
        return <MoveInflowCalculationModal />
      } else if (formStore.newCalculationModalIsOpen) {
        return <NewInflowCalculationModal guest={!authStore.hasToken} />
      } else if (formStore.newCalculationModalGuestIsOpen) {
        return <NewInflowCalculationGuestModal />
      } else if (formStore.resumeCalculationModalGuestIsOpen) {
        return <ResumeInflowCalculationGuestModal />
      }
      return null
    }
  }

  return (
    <>
      {openedModal()}
      <Grid>
        <Header>
          <Logo />
          {authStore.hasToken ? (
            <LogoutButton suppressHydrationWarning onClick={logout}>{t('buttons.logout')}</LogoutButton>
          ) : (
            <LogoutButton suppressHydrationWarning onClick={login}>{t('buttons.login')}</LogoutButton>
          )}
        </Header>
        <SubHeader>
          {router.pathname.includes('costs-calculation') ? t('subtitle-instrument-2')
            : (router.pathname.includes('inflow-calculation') ? t('subtitle-instrument-1') : '')}
        </SubHeader>
        <ContentWrapper>
          {router.pathname === '/inflow-calculation/introduction' ||
          router.pathname === '/login-with-token' ||
          router.pathname === '/costs-calculation/introduction' ? (
            React.Fragment
          ) : (router.pathname.split('/')[1] === 'costs-calculation' ? (
            <SidenavCostsCalculationInstrument />
          ) : (router.pathname.split('/')[1] === 'inflow-calculation' ? (
            <SidenavInflowCalculationInstrument />
          ) : null ))}
          {children}
        </ContentWrapper>
      </Grid>
    </>
  )
})
