import gql from 'graphql-tag';

export const MY_MOST_RECENT_INFLOW_CALCULATION = gql`
  query forms {
    myInflowCalculationForms(order: { created: DESC }, take: 1) {
      items {
        id
        created
        currentUrl
        organisationName
        dataYear
        numberOfEmployees
        percentageHealthcareEmployees
        percentageNewEmployees
        percentageInflowEducation
        inflowBBLDuaal
        inflowBOL
        bBLoutflow {
          level5
          level4
          level3
          level2
          level2Shortened
          level1
        }
        bOLoutflow {
          level5
          level4
          level3
          level2
          level2Shortened
          level1
        }
      }
    }
  }
`