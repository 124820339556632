import { styled } from '@styles/config'

export const Header = styled('div', {
  gridArea: 'header',
  height: '92px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$white',
})

export const SubHeader = styled('div', {
  gridArea: 'subheader',
  height: '180px',
  background: '$primaryGradientLight',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '$large',
  fontWeight: '600',
  paddingTop: '32px',
})
