import { makeAutoObservable } from 'mobx'
import { RootStore } from '@stores/root'
import { startPersisting, stopPersisting } from 'mobx-persist-store'

export class AuthStore {
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this)
  }

  get hasToken() {
    if (typeof window === 'undefined') {
      return false
    }
    return !!localStorage.getItem('auth:token')
  }

  setToken(token: string) {
    if (typeof window === 'undefined') {
      return
    }
    localStorage.setItem('auth:token', token)

    if (this.rootStore.formStore) {
      startPersisting(this.rootStore.formStore)
    }
    if (this.rootStore.costStore) {
      startPersisting(this.rootStore.costStore)
    }
  }

  removeToken() {
    if (typeof window === 'undefined') {
      return
    }
    localStorage.removeItem('auth:token')

    if (this.rootStore.formStore) {
      stopPersisting(this.rootStore.formStore)
    }
    if (this.rootStore.costStore) {
      stopPersisting(this.rootStore.costStore)
    }
  }
}
