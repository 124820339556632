import { CheckmarkIcon } from '@/Icons/CheckmarkIcon'
import { WarningIcon } from '@/Icons/WarningIcon'
import { styled } from '@styles/config'
import { observer } from 'mobx-react'
import Link from 'next/link'
import { NextRouter } from 'next/router'
import React from 'react'

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '7px',
  margin: '14px 0 0 0',
})

const IconWrapper = styled('div', {
  height: '16px',
  width: '16px',
})

const Nav = styled('a', {
  textDecoration: 'none',
  width: '100%',
  cursor: 'pointer',
  fontSize: '$small',
  fontWeight: '400',
  color: '$gray500',

  '&.active': {
    color: '$primaryMagenta',
  },
})

interface NavItem {
  router: NextRouter
  path: string
  text: string
  icon: 'none' | 'completed' | 'warning'
}

const NavItem = observer(({ router, path, text, icon }: NavItem) => {
  return (
    <Container>
      <IconWrapper>
        {icon === 'completed' ? (
          <CheckmarkIcon />
        ) : icon === 'warning' ? (
          <WarningIcon />
        ) : (
          React.Fragment
        )}
      </IconWrapper>
      <Link href={path} passHref>
        <Nav className={router.pathname == path ? 'active' : ''}>{text}</Nav>
      </Link>
    </Container>
  )
})

export default NavItem
