import { Modal } from '@/Modal/Modal'
import { useStore } from '@stores/index'
import useTranslation from 'next-translate/useTranslation'
import router from 'next/router'

export const ResumeInflowCalculationGuestModal = () => {
  const { t } = useTranslation('common')
  const { formStore, costStore } = useStore()

  const resumeInflowCalculation = () => {
    formStore.setIsNewForm(false)
    formStore.setResumeCalculationModalGuestIsOpen()
    router.push(formStore.progress !== 100 ?
      (formStore.currentUrl || '/inflow-calculation/organisation-details/name') :
      (costStore.currentUrl || '/costs-calculation/basic-information/internship-duration')
    )
  }

  return (
    <Modal
      confirm={() => resumeInflowCalculation()}
      close={formStore.setResumeCalculationModalGuestIsOpen}
      title={t('modal.resume-calculation-confirmation-title')}
      text={t('modal.resume-calculation-warning-guest')}
    ><a href={process.env.NEXT_PUBLIC_WGV_EMPLOYER_MEETINSTRUMENTEN_URL!}>{t('modal.login')}</a>.</Modal>
  )
}
