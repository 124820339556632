import { CalendarIcon } from '@/Icons/CalculatorIcon'
import { useStore } from '@stores/index'
import { styled } from '@styles/config'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Button } from './Button'
import { SideButton } from './SideButton'

const StyledButton = styled(Button, {
  color: '$gray800',
  boxShadow: '$card',
  backgroundColor: '$white',
  borderRadius: '$radius',
  height: '43px',
})

const ButtonContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
  fontSize: '$xsmall',
})

export const NewCalculationSideButton = () => {
  const { t } = useTranslation('common')
  const { formStore } = useStore()
  const router = useRouter()

  return (
    <SideButton
      icon={<CalendarIcon />}
      iconPosition='start'
      text={t('buttons.new')}
      onClick={formStore.setNewCalculationModalIsOpen}
    />
  )
}
