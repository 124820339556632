import { styled } from '@styles/config'
import { Button } from './Button'

export const LogoutButton = styled(Button, {
  marginLeft: 'auto',
  height: '100%',
  width: '200px',
  background: '$primaryMagenta',
  color: 'white',
})
