import { makeAutoObservable } from 'mobx'
import { RootStore } from '@stores/root'
import { makePersistable } from 'mobx-persist-store'

/**
 * The purpose of this store is to save the visited fields and persist them to memory. This is not the best solution but
 * allows us to use different persist settings between this store and the form store.
 */
export class VisitedStore {
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this)
    if (typeof window !== 'undefined') {
      makePersistable(this, {
        name: 'visited-store',
        properties: ['visitedInflow', 'visitedCosts'],
        storage: window.localStorage,
      })
    }
  }

  visitedInflow: string[] = []
  visitedCosts: string[] = []

  addVisitedInflow = (field: string) => {
    if (!this.visitedInflow?.includes(field)) {
      this.visitedInflow?.push(field)
    }
  }

  addVisitedCosts = (field: string) => {
    if (!this.visitedCosts?.includes(field)) {
      this.visitedCosts?.push(field)
    }
  }

  clearVisitedInflow = () => {
    this.visitedInflow = []
  }

  clearVisitedCosts = () => {
    this.visitedCosts = []
  }
}
