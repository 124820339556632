import { NewTabIcon } from '@/Icons/NewTabIcon'
import { styled } from '@styles/config'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { StyledButton } from './SideButton'

const Button = styled(StyledButton, {
  height: 'auto',
  fontSize: '$xsmall',
  textAlign: 'start',
  padding: '10px 24px 10px 24px',
  '& svg': {
    margin: '0 0 0 5px',
    position: 'relative',
    top: '2px',
    '& path': {
      fill: '$gray800',
    },
  },
})

export const PreviousCalculationButton = () => {
  const { t } = useTranslation('common')

  return (
    <Button onClick={() => window.open('/inflow-calculation/results', '_blank')}>
      {t('buttons.previous-calculation')}
      <NewTabIcon />
    </Button>
  )
}
