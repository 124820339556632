
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ApolloProvider } from '@apollo/client'
import { useApollo } from '@services/apollo'
import { useStore } from '@stores/index'
import '@styles/reset.css'
import { observer } from 'mobx-react'
import { Layout } from '@/Layout/Layout'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import Head from 'next/head'

const MyApp = ({ Component, pageProps }: AppProps) => {
  const apolloClient = useApollo()
  const { formStore, costStore } = useStore()

  const path = useRouter().pathname

  useEffect(() => {
    if (
      path !== '/inflow-calculation/introduction' &&
      path !== '/costs-calculation/introduction' &&
      path !== '/_error'
    ) {
      const split = path.split('/')[1]
      if (split === 'inflow-calculation') {
        formStore.setCurrentUrl(path)
      } else if (split === 'costs-calculation') {
        costStore.setCurrentUrl(path)
      }
    }
  }, [path])

  const ObserverComponent = observer(Component)

  return (
    <ApolloProvider client={apolloClient}>
      <Layout>
        <Toaster />
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#DB0F68" />
          <title>WGV Rekeninstrument</title>
        </Head>
        <ObserverComponent {...pageProps} />
      </Layout>
    </ApolloProvider>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  