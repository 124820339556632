import { Modal } from '@/Modal/Modal'
import { useStore } from '@stores/index'
import useTranslation from 'next-translate/useTranslation'
import router from 'next/router'

export const NewInflowCalculationGuestModal = () => {
  const { t } = useTranslation('common')
  const { formStore } = useStore()

  const newInflowCalculation = () => {
    formStore.createInflowCalculationForm()
    formStore.setNewCalculationModalGuestIsOpen()
    router.push('/inflow-calculation/organisation-details/name')
  }

  return (
    <Modal
      confirm={() => newInflowCalculation()}
      close={formStore.setNewCalculationModalGuestIsOpen}
      title={t('modal.new-calculation-confirmation-title')}
      text={t('modal.new-calculation-warning-guest')}
    ><a href={process.env.NEXT_PUBLIC_WGV_EMPLOYER_MEETINSTRUMENTEN_URL!}>{t('modal.login')}</a>.</Modal>
  )
}
