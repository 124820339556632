import useTranslation from 'next-translate/useTranslation'
import { StyledButton } from './NavButton'

interface MoveButton {
  onClick: () => void
  size: 'small' | 'medium' | 'large'
}

export const MoveButton = ({ onClick, size }: MoveButton) => {
  const { t } = useTranslation('common')
  return (
    <StyledButton size={size} action='primary' onClick={onClick}>
      {t('buttons.move')}
    </StyledButton>
  )
}
