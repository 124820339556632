import { styled } from '@styles/config'
import React from 'react'
import { Button } from './Button'

export const StyledButton = styled(Button, {
  color: '$gray800',
  boxShadow: '$card',
  backgroundColor: '$white',
  borderRadius: '$radius',
  height: '43px',
  maxWidth: '280px',
})

const ButtonContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
  fontSize: '$xsmall',
})

interface SideButton {
  onClick: () => void
  icon?: React.ReactElement
  iconPosition?: 'start' | 'end'
  text: string
}

export const SideButton = ({ onClick, icon, iconPosition, text }: SideButton) => {
  return (
    <StyledButton onClick={onClick}>
      <ButtonContent>
        {iconPosition === 'start' ? icon : React.Fragment}
        {text}
        {iconPosition === 'end' ? icon : React.Fragment}
      </ButtonContent>
    </StyledButton>
  )
}
