import { ButtonWrapper } from '@/Buttons/ButtonWrapper'
import { CancelButton } from '@/Buttons/CancelButton'
import { ConfirmButton } from '@/Buttons/ConfirmButton'
import { Card } from '@/Card/Card'
import { styled } from '@styles/config'
import React from 'react'

const ModalBackground = styled('div', {
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(248, 250, 252, 0.3)',
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  'z-index': 999999,
})

const ModalContainer = styled(Card, {
  maxWidth: '400px',
  minHeight: '300px',
  display: 'flex',
  padding: '25px',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '2px',
})

const Title = styled('div', {
  fontSize: '$medium',
  fontWeight: '600',
})

const Text = styled('div', {
  fontSize: '$small',
})

interface Modal {
  close: () => void
  confirm: () => void
  title: string
  text: string
  children?: React.ReactNode
}

export const Modal = ({ close, confirm, title, text, children }: Modal) => {
  return (
    <ModalBackground>
      <ModalContainer>
        <Header>
          <Title>{title}</Title>
        </Header>
        <Text>{text}{children}</Text>
        <ButtonWrapper>
          <CancelButton size='medium' onClick={close} />
          <ConfirmButton size='medium' onClick={confirm} />
        </ButtonWrapper>
      </ModalContainer>
    </ModalBackground>
  )
}
