import { NewCalculationSideButton } from '@/Buttons/NewCalculationSideButton'
import { ResetButton } from '@/Buttons/ResetButton'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useStore } from '@stores/index'
import NavItem from './NavItem'
import { NavContainer, Sidenav, StepTitle } from './Sidenav'
import { observer } from 'mobx-react'
import { icon } from './SidenavCostsCalculationInstrument'

const SidenavInflowCalculationInstrument = observer(() => {
  const router = useRouter()
  const { formStore } = useStore()
  const { t } = useTranslation('common')

  return (
    <Sidenav>
      <NavContainer>
        <StepTitle>{t('nav.basic-information')}</StepTitle>

        <NavItem
          router={router}
          path='/inflow-calculation/organisation-details/name'
          text={t('nav.organisation-name')}
          icon={icon(formStore.steps.organisationName())}
        />
        <NavItem
          router={router}
          path='/inflow-calculation/organisation-details/year-data'
          text={t('nav.year-data')}
          icon={icon(formStore.steps.dataYear())}
        />
        <NavItem
          router={router}
          path='/inflow-calculation/organisation-details/employees'
          text={t('nav.num-employees')}
          icon={icon(formStore.steps.numberOfEmployees())}
        />
        <NavItem
          router={router}
          path='/inflow-calculation/organisation-details/percentage-healthcare'
          text={t('nav.healthcare-employees')}
          icon={icon(formStore.steps.percentageHealthcareEmployees())}
        />
        <NavItem
          router={router}
          path='/inflow-calculation/organisation-details/replacements'
          text={t('nav.employee-replacements')}
          icon={icon(formStore.steps.percentageNewEmployees())}
        />
        <NavItem
          router={router}
          path='/inflow-calculation/organisation-details/inflow-education'
          text={t('nav.inflow-education')}
          icon={icon(formStore.steps.percentageInflowEducation())}
        />
        <NavItem
          router={router}
          path='/inflow-calculation/organisation-details/inflow-bbl-duaal'
          text={t('nav.inflow-BBL')}
          icon={icon(formStore.steps.inflowBBLDuaal())}
        />
        <StepTitle>{t('nav.calculation-BBL')}</StepTitle>
        <NavItem
          router={router}
          path='/inflow-calculation/calculation-bbl'
          text={t('nav.outflow-BBL')}
          icon={icon(formStore.steps.BBLoutflow())}
        />
        <StepTitle>{t('nav.calculation-BOL')}</StepTitle>
        <NavItem
          router={router}
          path='/inflow-calculation/calculation-bol'
          text={t('nav.outflow-BOL')}
          icon={icon(formStore.steps.BOLoutflow())}
        />
        <StepTitle>{t('nav.results-step')}</StepTitle>
        <NavItem router={router} path='/inflow-calculation/results' text={t('nav.results')} icon='none' />
      </NavContainer>
      <ResetButton onClick={() => formStore.reset()}>{t('buttons.reset')}</ResetButton>
      <NewCalculationSideButton />
    </Sidenav>
  )
})

export default SidenavInflowCalculationInstrument
