import { ButtonWrapper } from '@/Buttons/ButtonWrapper'
import { CancelButton } from '@/Buttons/CancelButton'
import { Card } from '@/Card/Card'
import { styled } from '@styles/config'
import React from 'react'
import { WipeButton } from '@/Buttons/WipeButton'
import { MoveButton } from '@/Buttons/MoveButton'

const ModalBackground = styled('div', {
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(248, 250, 252, 0.3)',
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  'z-index': 999999,
})

const ModalContainer = styled(Card, {
  maxWidth: '400px',
  minHeight: '300px',
  display: 'flex',
  padding: '25px',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '2px',
})

const Title = styled('div', {
  fontSize: '$medium',
  fontWeight: '600',
})

const Text = styled('div', {
  fontSize: '$small',
})

interface MoveModal {
  close: () => void
  wipe: () => void
  move: () => void
  title: string
  text: string
}

export const MoveModal = ({ close, wipe, move, title, text }: MoveModal) => {
  return (
    <ModalBackground>
      <ModalContainer>
        <Header>
          <Title>{title}</Title>
        </Header>
        <Text>{text}</Text>
        <ButtonWrapper>
          <CancelButton size='medium' onClick={close} />
          <WipeButton size='medium' onClick={wipe} />
          <MoveButton size='medium' onClick={move} />
        </ButtonWrapper>
      </ModalContainer>
    </ModalBackground>
  )
}
