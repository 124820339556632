import gql from 'graphql-tag'

export const DEFAULT_INFLOW_CALCULATION_FORM = gql`
  query DefaultInflowCalculationForm {
    defaultInflowCalculationForm {
      id
      currentUrl
      organisationName
      dataYear
      numberOfEmployees
      percentageHealthcareEmployees
      percentageNewEmployees
      percentageInflowEducation
      inflowBBLDuaal
      inflowBOL
      bBLoutflow {
        level5
        level4
        level3
        level2
        level2Shortened
        level1
      }
      bOLoutflow {
        level5
        level4
        level3
        level2
        level2Shortened
        level1
      }
    }
  }
`