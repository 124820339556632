export const CheckmarkIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00008 0.666687C3.95008 0.666687 0.666748 3.95002 0.666748 8.00002C0.666748 12.05 3.95008 15.3334 8.00008 15.3334C12.0501 15.3334 15.3334 12.05 15.3334 8.00002C15.3334 3.95002 12.0501 0.666687 8.00008 0.666687ZM11.1787 6.76002C11.2373 6.69312 11.2818 6.61518 11.3098 6.5308C11.3378 6.44642 11.3486 6.3573 11.3416 6.26868C11.3346 6.18006 11.31 6.09373 11.2691 6.01477C11.2283 5.93581 11.1721 5.86582 11.1038 5.80891C11.0355 5.75201 10.9565 5.70933 10.8715 5.68339C10.7865 5.65746 10.6971 5.6488 10.6087 5.65791C10.5202 5.66702 10.4345 5.69372 10.3566 5.73644C10.2786 5.77917 10.21 5.83705 10.1547 5.90669L7.28808 9.34602L5.80475 7.86202C5.67901 7.74058 5.51061 7.67339 5.33581 7.6749C5.16102 7.67642 4.99381 7.74654 4.8702 7.87014C4.7466 7.99375 4.67648 8.16095 4.67496 8.33575C4.67345 8.51055 4.74064 8.67895 4.86208 8.80469L6.86208 10.8047C6.92759 10.8702 7.00601 10.9213 7.09236 10.9547C7.17871 10.9882 7.27108 11.0033 7.36359 10.9991C7.45611 10.9949 7.54673 10.9715 7.62969 10.9303C7.71265 10.8892 7.78611 10.8312 7.84541 10.76L11.1787 6.76002Z'
        fill='#16C733'
      />
    </svg>
  )
}
