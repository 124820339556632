import { useStore } from '@stores/index'
import useTranslation from 'next-translate/useTranslation'
import { MoveModal } from '@/Modal/MoveModal'
import router from 'next/router'

export const MoveCostsCalculationModal = () => {
  const { t } = useTranslation('common')
  const { formStore, costStore } = useStore()

  const wipe = async () => {
    await costStore.removeLocal()
    formStore.setMoveCalculationModalIsOpen()
    router.push('/costs-calculation/basic-information/internship-duration')
  }

  const move = async () => {
    await costStore.moveLocal()
    formStore.setMoveCalculationModalIsOpen()
    router.push(costStore.currentUrl ||'/costs-calculation/basic-information/internship-duration')
  }

  return (
    <MoveModal
      wipe={wipe}
      move={move}
      close={formStore.setMoveCalculationModalIsOpen}
      title={t('modal.move-calculation-confirmation-title')}
      text={t('modal.move-calculation-warning')}
    ></MoveModal>
  )
}
