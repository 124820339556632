import { styled } from '@styles/config'
import { Button } from './Button'

export const ResetButton = styled(Button, {
  boxShadow: '$card',
  height: '43px',
  borderRadius: '$radius',
  color: '$gray500',
  backgroundColor: '$white',
  width: '100%',
  fontSize: '$xsmall',
  '&:hover': {
    color: '$redHover',
  },
})
