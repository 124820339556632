import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { getApolloClient } from '@services/apollo'
import { RootStore } from '@stores/root'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { clearPersistedStore, hydrateStore, makePersistable, pausePersisting } from 'mobx-persist-store'
import { CREATE_INFLOW_CALCULATION_FORM } from 'mutations/createInflowCalculationForm'
import { StepStatus } from './costStore'
import { AuthStore } from '@stores/authStore'
import { UPDATE_INFLOW_CALCULATION_FORM } from '../mutations/updateInflowCalculationForm'
import { MY_MOST_RECENT_INFLOW_CALCULATION } from '../queries/myMostRecentInflowCalculation'
import { GET_BBL_CONSTANTS } from '../queries/getBblConstants'
import { GET_BOL_CONSTANTS } from '../queries/getBolConstants'
import { DEFAULT_INFLOW_CALCULATION_FORM } from '../queries/defaultInflowCalculationForm'
import { VisitedStore } from '@stores/visitedStore'

const PERSIST_CHANGES_DELAY = 2000

interface OutflowObject {
  level5?: number | null
  level4?: number | null
  level3?: number | null
  level2?: number | null
  level2Shortened?: number | null
  level1?: number | null
}

interface CalculationObject {
  id: number
  created: string
  organisationName: string
  dataYear: number
  numberOfEmployees: number
  percentageHealthcareEmployees: number
  percentageNewEmployees: number
  percentageInflowEducation: number
  inflowBBLDuaal: number
  inflowBOL: number
  BBLoutflow: OutflowObject
  BOLoutflow: OutflowObject
}

interface BblConstantsObject {
  Level5: {
    Years: number
    EducationYield: number
    SectorYield: number
  }
  Level4: {
    Years: number
    EducationYield: number
    SectorYield: number
  }
  Level3: {
    Years: number
    EducationYield: number
    SectorYield: number
  }
  Level2: {
    Years: number
    EducationYield: number
    SectorYield: number
  }
  Level2shortened: {
    Years: number
    EducationYield: number
    SectorYield: number
  }
  Level1: {
    Years: number
    EducationYield: number
    SectorYield: number
  }
}

interface BolConstantsObject {
  Level5: {
    Years: number
    EducationYield: number
    SectorYield: number
    FractionBpv: number
  }
  Level4: {
    Years: number
    EducationYield: number
    SectorYield: number
    FractionBpv: number
  }
  Level3: {
    Years: number
    EducationYield: number
    SectorYield: number
    FractionBpv: number
  }
  Level2: {
    Years: number
    EducationYield: number
    SectorYield: number
    FractionBpv: number
  }
  Level2shortened: {
    Years: number
    EducationYield: number
    SectorYield: number
    FractionBpv: number
  }
  Level1: {
    Years: number
    EducationYield: number
    SectorYield: number
    FractionBpv: number
  }
}

export class FormStore {
  client: ApolloClient<NormalizedCacheObject>
  authStore: AuthStore
  visitedStore: VisitedStore

  constructor(private rootStore: RootStore) {
    this.client = getApolloClient()
    this.authStore = rootStore.authStore
    this.visitedStore = rootStore.visitedStore
    makeAutoObservable(this, { client: false })
    if (typeof window !== 'undefined') {
      makePersistable(this, {
        name: 'inflow-form-store',
        properties: [
          'id',
          'organisationName',
          'currentUrl',
          'dataYear',
          'numberOfEmployees',
          'percentageHealthcareEmployees',
          'percentageNewEmployees',
          'percentageInflowEducation',
          'inflowBBLDuaal',
          'inflowBOL',
          'BBLoutflow',
          'BOLoutflow',
        ],
        storage: window.localStorage,
      }, { delay: PERSIST_CHANGES_DELAY }).then(() => {
        this.fetchFormData()
        this.fetchCalculationConstants()
        this.isNewForm = false
      })
    }

    reaction(
      () =>
        JSON.stringify(this.getFieldsToStore()),
      (_) => {
        if (this.valid && this.authStore.hasToken) {
          this.client
            .mutate({
              mutation: UPDATE_INFLOW_CALCULATION_FORM,
              variables: {
                input: this.getFieldsToStore(),
              },
            })
            .catch((error) => console.error(error))
        }
      },
      { delay: PERSIST_CHANGES_DELAY }
    )
  }

  async fetchFormData() {
    if (typeof window === 'undefined') {
      return
    }
    if (this.authStore.hasToken) {
      pausePersisting(this)
      this.reset()
      try {
        const response = await this.client.query({
          query: MY_MOST_RECENT_INFLOW_CALCULATION,
        })
        if (response.data.myInflowCalculationForms.items.length > 0) {
          const mostRecentCalculation = response.data.myInflowCalculationForms.items[0]
          this.setFieldsWithDataObject(mostRecentCalculation)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  createInflowCalculationForm = async () => {
    if (typeof window === 'undefined') {
      return
    }
    try {
      if (this.authStore.hasToken) {
        const response = await this.client.mutate({
          mutation: CREATE_INFLOW_CALCULATION_FORM,
        })
        this.setFieldsWithDataObject(response.data.createInflowCalculationForm)
      } else {
        const response = await this.client.query({
          query: DEFAULT_INFLOW_CALCULATION_FORM,
        })
        this.setFieldsWithDataObject(response.data.defaultInflowCalculationForm)
      }
    } catch (error) {
      console.error(error)
    }
    this.visitedStore.clearVisitedInflow()
  }

  async fetchCalculationConstants() {
    if (typeof window === 'undefined') {
      return
    }
    try {
      const bblResponse = await this.client.query({
        query: GET_BBL_CONSTANTS,
      })
      this.constantsBBL = JSON.parse(bblResponse.data.calculationConstants[0].value)
      const bolResponse = await this.client.query({
        query: GET_BOL_CONSTANTS,
      })
      this.constantsBOL = JSON.parse(bolResponse.data.calculationConstants[0].value)
    } catch (error) {
      console.error(error)
    }
  }

  getFieldsToStore() {
    return {
      id: this.id,
      organisationName: this.organisationName,
      currentUrl: this.currentUrl,
      dataYear: this.dataYear,
      numberOfEmployees: this.numberOfEmployees,
      percentageHealthcareEmployees: this.percentageHealthcareEmployees,
      percentageNewEmployees: this.percentageNewEmployees,
      percentageInflowEducation: this.percentageInflowEducation,
      inflowBBLDuaal: this.inflowBBLDuaal,
      inflowBOL: this.inflowBOL,
      bBLoutflow: this.BBLoutflow,
      bOLoutflow: this.BOLoutflow,
    }
  }

  setFieldsWithDataObject = (data: any) => {
    runInAction(() => {
      this.organisationName = data.organisationName
      this.dataYear = data.dataYear
      this.id = data.id
      this.numberOfEmployees = data.numberOfEmployees
      this.percentageHealthcareEmployees = data.percentageHealthcareEmployees
      this.percentageInflowEducation = data.percentageInflowEducation
      this.percentageNewEmployees = data.percentageNewEmployees
      this.inflowBBLDuaal = data.inflowBBLDuaal
      this.inflowBOL = data.inflowBOL
      this.BBLoutflow = data.bBLoutflow || {}
      this.BOLoutflow = data.bOLoutflow || {}
      this.currentUrl = data.currentUrl
    })
  }

  removeLocal() {
    return clearPersistedStore(this)
  }

  async moveLocal() {
    try {
      const response = await this.client.mutate({
        mutation: CREATE_INFLOW_CALCULATION_FORM,
      })
      await hydrateStore(this).then(() => clearPersistedStore(this))
      this.setId(response.data.createInflowCalculationForm.id)
    } catch (error) {
      console.error(error)
    }
  }

  steps = {
    organisationName: () => {
      return this.errorOrganisationName
        ? StepStatus.WARNING
        : this.organisationName && (this.visitedStore.visitedInflow.includes('organisationName') || !this.isNewForm)
        ? StepStatus.COMPLETED
        : StepStatus.NONE
    },
    dataYear: () => {
      return this.errorDataYear
        ? StepStatus.WARNING
        : this.dataYear && (this.visitedStore.visitedInflow.includes('dataYear') || !this.isNewForm)
        ? StepStatus.COMPLETED
        : StepStatus.NONE
    },
    numberOfEmployees: () => {
      return this.errorNumberOfEmployees
        ? StepStatus.WARNING
        : this.numberOfEmployees && (this.visitedStore.visitedInflow.includes('numberOfEmployees') || !this.isNewForm)
        ? StepStatus.COMPLETED
        : StepStatus.NONE
    },
    percentageHealthcareEmployees: () => {
      return this.errorPercentageHealthcareEmployees
        ? StepStatus.WARNING
        : this.percentageHealthcareEmployees &&
          (this.visitedStore.visitedInflow.includes('percentageHealthcareEmployees') || !this.isNewForm)
        ? StepStatus.COMPLETED
        : StepStatus.NONE
    },
    percentageNewEmployees: () => {
      return this.errorPercentageNewEmployees
        ? StepStatus.WARNING
        : this.percentageNewEmployees && (this.visitedStore.visitedInflow.includes('percentageNewEmployees') || !this.isNewForm)
        ? StepStatus.COMPLETED
        : StepStatus.NONE
    },
    percentageInflowEducation: () => {
      return this.errorPercentageInflowEducation
        ? StepStatus.WARNING
        : this.percentageInflowEducation &&
          (this.visitedStore.visitedInflow.includes('percentageInflowEducation') || !this.isNewForm)
        ? StepStatus.COMPLETED
        : StepStatus.NONE
    },
    inflowBBLDuaal: () => {
      return this.errorInflowBBLDuaal || this.errorInflowBOL || (this.remainingInflow || false) < 0
        ? StepStatus.WARNING
        : this.remainingInflow == 0
        ? StepStatus.COMPLETED
        : StepStatus.NONE
    },
    BBLoutflow: () => {
      return this.remainingBBL == 0 || (this.remainingInflow == 0 && !this.remainingBBL)
        ? StepStatus.COMPLETED
        : this.warningOutflowBBL || (this.remainingBBL || false) < 0
        ? StepStatus.WARNING
        : StepStatus.NONE
    },
    BOLoutflow: () => {
      return this.remainingBOL == 0 || (this.remainingInflow == 0 && !this.remainingBOL)
        ? StepStatus.COMPLETED
        : (this.warningOutflowBOL || this.remainingBOL || false) < 0 && this.inflowBOL
        ? StepStatus.WARNING
        : StepStatus.NONE
    },
  }

  get progress() {
    const stepStatuses = Object.values(this.steps)
    return (
      (stepStatuses.filter((stepStatus) => stepStatus() === StepStatus.COMPLETED).length / stepStatuses.length) * 100
    )
  }

  // constants
  constantsBBL: BblConstantsObject | null = null
  constantsBOL: BolConstantsObject | null = null

  // Last visited url by user
  currentUrl: string | null = null
  isNewForm: boolean = false

  // form values
  id: number | null = null
  // Basic info organisation
  organisationName?: string | null = null
  dataYear?: number | null = null
  numberOfEmployees?: number | null = null
  percentageHealthcareEmployees?: number | null = null
  percentageNewEmployees?: number | null = null
  percentageInflowEducation?: number | null = null
  inflowBBLDuaal?: number | null = null
  inflowBOL?: number | null = null

  // Booleans that determine whether a warning should be shown on the BBL and BOL calculation pages
  // The warnings are shown when remaingingBBL or remainingBOL was 0, but became another value because the user changes a previous input field
  warningOutflowBOL: boolean = false
  warningOutflowBBL: boolean = false

  // Outflow different levels
  BBLoutflow: OutflowObject = {}

  BOLoutflow: OutflowObject = {}

  newCalculationModalIsOpen: boolean = false
  setNewCalculationModalIsOpen = () => {
    this.newCalculationModalIsOpen = !this.newCalculationModalIsOpen
  }

  newCalculationModalGuestIsOpen: boolean = false
  setNewCalculationModalGuestIsOpen = () => {
    this.newCalculationModalGuestIsOpen = !this.newCalculationModalGuestIsOpen
  }

  resumeCalculationModalGuestIsOpen: boolean = false
  setResumeCalculationModalGuestIsOpen = () => {
    this.resumeCalculationModalGuestIsOpen = !this.resumeCalculationModalGuestIsOpen
  }

  moveCalculationModalIsOpen: boolean = false
  setMoveCalculationModalIsOpen = () => {
    this.moveCalculationModalIsOpen = !this.moveCalculationModalIsOpen
  }

  reset = () => {
    this.id = null
    this.organisationName = null
    this.dataYear = null
    this.numberOfEmployees = null
    this.percentageHealthcareEmployees = null
    this.percentageNewEmployees = null
    this.percentageInflowEducation = 38
    this.inflowBBLDuaal = null
    this.inflowBOL = null
    this.resetBBLoutflow()
    this.resetBOLoutflow()
    this.warningOutflowBBL = false
    this.warningOutflowBOL = false
    this.isNewForm = true
    this.visitedStore.clearVisitedInflow()
  }

  resetBBLoutflow = () => {
    Object.keys(this.BBLoutflow).forEach((level: string) => (this.BBLoutflow[level as keyof OutflowObject] = null))
  }

  resetBOLoutflow = () => {
    Object.keys(this.BOLoutflow).forEach((level: string) => (this.BOLoutflow[level as keyof OutflowObject] = null))
  }

  setWarningOutflowBBL = () => (this.warningOutflowBBL = !this.warningOutflowBBL)
  setWarningOutflowBOL = () => (this.warningOutflowBOL = !this.warningOutflowBOL)

  setId = (id: number) => (this.id = id)
  // Set basic info
  setOrganisationName = (organisationName: string) => (this.organisationName = organisationName || null)
  setDataYear = (dataYear: number) => (this.dataYear = dataYear || null)

  setCurrentUrl = (url: string) => (this.currentUrl = url)

  setIsNewForm = (value: boolean) => (this.isNewForm = value)

  setNumberOfEmployees = (numberOfEmployees: number) => {
    // If the remainingBOL was 0 (the BOL distribution was complete), changing the numberOfEmployees, makes the OutflowBBL incomplete
    // remaingBOL is undefined if the user has not filled in the bol calculation yet, so this does not trigger a warning
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
    this.numberOfEmployees = numberOfEmployees || null
    // Remove the warning if the remainingBOL becomes 0 again after changing numberOfEmployees
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
  }

  setPercentageHealthcareEmployees = (percentageHealthcareEmployees: number) => {
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
    this.percentageHealthcareEmployees = percentageHealthcareEmployees || null
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
  }

  setPercentageNewEmployees = (percentageNewEmployees: number) => {
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
    this.percentageNewEmployees = percentageNewEmployees || null
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
  }

  setPercentageInflowEducation = (percentageInflowEducation: number) => {
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
    this.percentageInflowEducation = percentageInflowEducation || null
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
  }

  setInflowBBLDuaal = (inflowBBLDuaal: number) => {
    if (this.remainingBBL === 0) {
      this.setWarningOutflowBBL()
    }
    this.inflowBBLDuaal = inflowBBLDuaal || null
    this.inflowBOL = this.totalInflow ? this.totalInflow - inflowBBLDuaal : this.inflowBOL
    if (!this.inflowBBLDuaal) {
      this.resetBBLoutflow()
      return
    }
    if (!this.inflowBOL) {
      this.resetBOLoutflow()
      return
    }
    if (this.remainingBBL === 0) {
      this.setWarningOutflowBBL()
    }
  }

  setInflowBOL = (inflowBOL: number) => {
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
    this.inflowBOL = inflowBOL || null
    this.inflowBBLDuaal = this.totalInflow ? this.totalInflow - inflowBOL : this.inflowBOL
    if (!this.inflowBOL) {
      this.resetBOLoutflow()
      return
    }
    if (!this.inflowBBLDuaal) {
      this.resetBBLoutflow()
      return
    }
    if (this.remainingBOL === 0) {
      this.setWarningOutflowBOL()
    }
  }

  // Set outflow BBL
  setOutflowBBLlevel5 = (outflow?: number) => (this.BBLoutflow.level5 = outflow)
  setOutflowBBLlevel4 = (outflow?: number) => (this.BBLoutflow.level4 = outflow)
  setOutflowBBLlevel3 = (outflow?: number) => (this.BBLoutflow.level3 = outflow)
  setOutflowBBLlevel2 = (outflow?: number) => (this.BBLoutflow.level2 = outflow)
  setOutflowBBLlevel2Shortened = (outflow?: number) => (this.BBLoutflow.level2Shortened = outflow)
  setOutflowBBLlevel1 = (outflow?: number) => (this.BBLoutflow.level1 = outflow)

  // Set outflow BOL
  setOutflowBOLlevel5 = (outflow?: number) => (this.BOLoutflow.level5 = outflow)
  setOutflowBOLlevel4 = (outflow?: number) => (this.BOLoutflow.level4 = outflow)
  setOutflowBOLlevel3 = (outflow?: number) => (this.BOLoutflow.level3 = outflow)
  setOutflowBOLlevel2 = (outflow?: number) => (this.BOLoutflow.level2 = outflow)
  setOutflowBOLlevel2Shortened = (outflow?: number) => (this.BOLoutflow.level2Shortened = outflow)
  setOutflowBOLlevel1 = (outflow?: number) => (this.BOLoutflow.level1 = outflow)

  get hasForm() {
    return !!this.id
  }

  get hasLocalForm() {
    if (typeof window === 'undefined') {
      return false
    }
    return !!JSON.parse(localStorage.getItem('inflow-form-store') ?? 'null')?.id
  }

  // Input validation

  get valid() {
    return (
      this.validId &&
      this.validOrganisationName &&
      this.validDataYear &&
      this.validCurrentUrl &&
      this.validNumberOfEmployees &&
      this.validInflowBBLDuaal &&
      this.validInflowBOL &&
      this.validPercentageHealthcareEmployees &&
      this.validPercentageInflowEducation &&
      this.validPercentageNewEmployees
    )
  }

  get validId() {
    return this.id !== null
  }

  get validOrganisationName() {
    return (
      ((this.organisationName?.length ?? false) > 0 && (this.organisationName?.length ?? false) <= 255) ||
      this.organisationName === null
    )
  }

  get validDataYear() {
    return (
      ((this.dataYear ?? false) > 0 &&
        (this.dataYear ?? false) <= new Date().getFullYear() &&
        Number.isInteger(this.dataYear)) ||
      this.dataYear === null
    )
  }

  get validCurrentUrl() {
    return (
      ((this.currentUrl?.length ?? false) > 0 && (this.currentUrl?.length ?? false) <= 255) || this.currentUrl === null
    )
  }

  get validNumberOfEmployees() {
    return (this.numberOfEmployees ?? false) > 0 || this.numberOfEmployees === null
  }

  get validPercentageHealthcareEmployees() {
    return this.isPercentage(this.percentageHealthcareEmployees) || this.percentageHealthcareEmployees === null
  }

  get validPercentageInflowEducation() {
    return this.isPercentage(this.percentageInflowEducation) || this.percentageInflowEducation === null
  }

  get validPercentageNewEmployees() {
    return this.isPercentage(this.percentageNewEmployees) || this.percentageNewEmployees === null
  }

  get validInflowBBLDuaal() {
    return (
      !this.inflowBBLDuaal ||
      (this.inflowBBLDuaal <= (this.totalInflow ?? true) &&
        Number.isInteger(this.inflowBBLDuaal) &&
        this.inflowBBLDuaal >= 0)
    )
  }

  get validInflowBOL() {
    return (
      !this.inflowBOL ||
      (this.inflowBOL <= (this.totalInflow ?? true) && Number.isInteger(this.inflowBOL) && this.inflowBOL >= 0)
    )
  }

  isPercentage(value?: number | string | null) {
    if (!value) {
      return undefined
    }
    return value >= 0 && value <= 100
  }

  get errorOrganisationName() {
    if (!this.organisationName) return undefined
    if (!this.validOrganisationName) {
      return 'De naam van de organisatie mag maximaal 255 karakters hebben'
    }
  }

  get errorDataYear() {
    if (!this.dataYear) return undefined
    if (!this.validDataYear) {
      return 'Je moet een jaar invullen dat kleiner of gelijk is aan het huidige jaar'
    }
  }

  get errorPercentageHealthcareEmployees() {
    if (!this.percentageHealthcareEmployees) return undefined
    if (!this.isPercentage(this.percentageHealthcareEmployees)) {
      return 'Je moet een percentage tussen 0 en 100 invullen'
    }
  }

  get errorNumberOfEmployees() {
    if (!this.numberOfEmployees) return undefined
    if (!Number.isInteger(this.numberOfEmployees)) {
      return 'Het aantal werknemers moet een geheel getal zijn'
    }
  }

  get errorPercentageNewEmployees() {
    if (!this.percentageNewEmployees) return undefined
    if (!this.isPercentage(this.percentageNewEmployees)) {
      return 'Je moet een percentage tussen 0 en 100 invullen'
    }
  }

  get errorPercentageInflowEducation() {
    if (!this.percentageInflowEducation) return undefined
    if (!this.isPercentage(this.percentageInflowEducation)) {
      return 'Je moet een percentage tussen 0 en 100 invullen'
    }
  }

  get errorInflowBBLDuaal() {
    if (!this.inflowBBLDuaal || !this.totalInflow) return undefined
    if (!Number.isInteger(this.inflowBBLDuaal)) {
      return 'Het aantal werknemers moet een geheel getal zijn.'
    }
    if (this.inflowBBLDuaal < 0) {
      return 'Instroom kan niet negatief zijn.'
    }
    if (this.inflowBBLDuaal > this.totalInflow) {
      return 'Instroom uit BBL kan niet groter zijn dan de totale instroom.'
    }
  }

  get errorInflowBOL() {
    if (!this.inflowBOL || !this.totalInflow) return undefined
    if (!Number.isInteger(this.inflowBOL)) {
      return 'Het aantal werknemers moet een geheel getal zijn.'
    }
    if (this.inflowBOL < 0) {
      return 'Instroom kan niet negatief zijn.'
    }
    if (this.inflowBOL > this.totalInflow) {
      return 'Instroom uit BOL kan niet groter zijn dan de totale instroom.'
    }
  }

  // Computeds

  get totalInflow() {
    if (
      !this.numberOfEmployees ||
      !this.percentageHealthcareEmployees ||
      !this.percentageNewEmployees ||
      !this.percentageInflowEducation
    ) {
      return undefined
    }
    return Math.round(
      this.numberOfEmployees *
        (this.percentageHealthcareEmployees * 0.01) *
        (this.percentageNewEmployees * 0.01) *
        (this.percentageInflowEducation * 0.01)
    )
  }

  get remainingInflow() {
    if (!this.totalInflow) {
      return undefined
    }
    return this.totalInflow - (this.inflowBBLDuaal || 0) - (this.inflowBOL || 0)
  }

  // Returns the number of BBL students that still have to be distributed accross the different BBL levels
  get remainingBBL() {
    if (!this.inflowBBLDuaal || !this.BBLoutflow) {
      return undefined
    }
    return this.inflowBBLDuaal - Object.values(this.BBLoutflow).reduce((sum, value) => sum + (value || 0), 0)
  }

  // Returns the number of BOL students that still have to be distributed accross the different BOL levels
  get remainingBOL() {
    if (!this.inflowBOL || !this.BOLoutflow) {
      return undefined
    }
    return this.inflowBOL - Object.values(this.BOLoutflow).reduce((sum, value) => sum + (value || 0), 0)
  }

  // BBL Results calculations

  // Dropout rates

  get dropoutPerYearBBLLevel5() {
    if (!this.employedBBLLevel5Year1 || !this.BBLoutflow.level5 || !this.constantsBBL) {
      return undefined
    }
    return (this.employedBBLLevel5Year1 - this.BBLoutflow.level5) / this.constantsBBL.Level5.Years
  }

  get dropoutPerYearBBLLevel4() {
    if (!this.employedBBLLevel4Year1 || !this.BBLoutflow.level4 || !this.constantsBBL) {
      return undefined
    }
    return (this.employedBBLLevel4Year1 - this.BBLoutflow.level4) / this.constantsBBL.Level4.Years
  }

  get dropoutPerYearBBLLevel3() {
    if (!this.employedBBLLevel3Year1 || !this.BBLoutflow.level3 || !this.constantsBBL) {
      return undefined
    }
    return (this.employedBBLLevel3Year1 - this.BBLoutflow.level3) / this.constantsBBL.Level3.Years
  }

  get dropoutPerYearBBLLevel2() {
    if (!this.employedBBLLevel2Year1 || !this.BBLoutflow.level2 || !this.constantsBBL) {
      return undefined
    }
    return (this.employedBBLLevel2Year1 - this.BBLoutflow.level2) / this.constantsBBL.Level2.Years
  }

  get dropoutPerYearBBLLevel2shortened() {
    if (!this.employedBBLLevel2ShortenedYear1 || !this.BBLoutflow.level2Shortened || !this.constantsBBL) {
      return undefined
    }
    return Math.round(
      (this.employedBBLLevel2ShortenedYear1 - this.BBLoutflow.level2Shortened) / this.constantsBBL.Level2.Years
    )
  }

  get dropoutPerYearBBLLevel1shortened() {
    if (!this.employedBBLLevel1Year1 || !this.BBLoutflow.level1 || !this.constantsBBL) {
      return undefined
    }
    return (this.employedBBLLevel1Year1 - this.BBLoutflow.level1) / this.constantsBBL.Level2.Years
  }

  // Number of BBL employed for different levels and years

  get employedBBLLevel5Year1() {
    return this.BBLoutflow.level5 && this.constantsBBL
      ? this.BBLoutflow.level5 / (this.constantsBBL.Level5.EducationYield * this.constantsBBL.Level5.SectorYield)
      : undefined
  }

  get employedBBLLevel5Year2() {
    return this.employedBBLLevel5Year1 && this.dropoutPerYearBBLLevel5
      ? this.employedBBLLevel5Year1 - this.dropoutPerYearBBLLevel5
      : undefined
  }

  get employedBBLLevel5Year3() {
    return this.employedBBLLevel5Year2 && this.dropoutPerYearBBLLevel5
      ? this.employedBBLLevel5Year2 - this.dropoutPerYearBBLLevel5
      : undefined
  }

  get employedBBLLevel5Year4() {
    return this.employedBBLLevel5Year3 && this.dropoutPerYearBBLLevel5
      ? this.employedBBLLevel5Year3 - this.dropoutPerYearBBLLevel5
      : undefined
  }

  get employedBBLLevel4Year1() {
    return this.BBLoutflow.level4 && this.constantsBBL
      ? this.BBLoutflow.level4 / (this.constantsBBL.Level4.EducationYield * this.constantsBBL.Level4.SectorYield)
      : undefined
  }

  get employedBBLLevel4Year2() {
    return this.employedBBLLevel4Year1 && this.dropoutPerYearBBLLevel4
      ? this.employedBBLLevel4Year1 - this.dropoutPerYearBBLLevel4
      : undefined
  }

  get employedBBLLevel4Year3() {
    return this.employedBBLLevel4Year2 && this.dropoutPerYearBBLLevel4
      ? this.employedBBLLevel4Year2 - this.dropoutPerYearBBLLevel4
      : undefined
  }

  get employedBBLLevel4Year4() {
    return this.employedBBLLevel4Year3 && this.dropoutPerYearBBLLevel4
      ? this.employedBBLLevel4Year3 - this.dropoutPerYearBBLLevel4
      : undefined
  }

  get employedBBLLevel3Year1() {
    return this.BBLoutflow.level3 && this.constantsBBL
      ? this.BBLoutflow.level3 / (this.constantsBBL.Level3.EducationYield * this.constantsBBL.Level3.SectorYield)
      : undefined
  }

  get employedBBLLevel3Year2() {
    return this.employedBBLLevel3Year1 && this.dropoutPerYearBBLLevel3
      ? this.employedBBLLevel3Year1 - this.dropoutPerYearBBLLevel3
      : undefined
  }

  get employedBBLLevel3Year3() {
    return this.employedBBLLevel3Year2 && this.dropoutPerYearBBLLevel3
      ? this.employedBBLLevel3Year2 - this.dropoutPerYearBBLLevel3
      : undefined
  }

  get employedBBLLevel2Year1() {
    return this.BBLoutflow.level2 && this.constantsBBL
      ? this.BBLoutflow.level2 / (this.constantsBBL.Level2.EducationYield * this.constantsBBL.Level2.SectorYield)
      : undefined
  }

  get employedBBLLevel2Year2() {
    return this.employedBBLLevel2Year1 && this.dropoutPerYearBBLLevel2
      ? this.employedBBLLevel2Year1 - this.dropoutPerYearBBLLevel2
      : undefined
  }

  get employedBBLLevel2ShortenedYear1() {
    return this.BBLoutflow.level2Shortened && this.constantsBBL
      ? this.BBLoutflow.level2Shortened /
          (this.constantsBBL.Level2shortened.EducationYield * this.constantsBBL.Level2shortened.SectorYield)
      : undefined
  }

  get employedBBLLevel1Year1() {
    return this.BBLoutflow.level1 && this.constantsBBL
      ? this.BBLoutflow.level1 / (this.constantsBBL.Level1.EducationYield * this.constantsBBL.Level1.SectorYield)
      : undefined
  }

  // The first two years of level 5 are full-time so they are not considered for this total
  get totalEmployedBBLLevel5() {
    if (!this.employedBBLLevel5Year3 || !this.employedBBLLevel5Year4) {
      return undefined
    }
    return this.employedBBLLevel5Year3 + this.employedBBLLevel5Year4
  }

  get totalEmployedBBLLevel4() {
    if (
      !this.employedBBLLevel4Year1 ||
      !this.employedBBLLevel4Year2 ||
      !this.employedBBLLevel4Year3 ||
      !this.employedBBLLevel4Year4
    ) {
      return undefined
    }
    return (
      this.employedBBLLevel4Year1 +
      this.employedBBLLevel4Year2 +
      this.employedBBLLevel4Year3 +
      this.employedBBLLevel4Year4
    )
  }

  get totalEmployedBBLLevel3() {
    if (!this.employedBBLLevel3Year1 || !this.employedBBLLevel3Year2 || !this.employedBBLLevel3Year3) {
      return undefined
    }
    return this.employedBBLLevel3Year1 + this.employedBBLLevel3Year2 + this.employedBBLLevel3Year3
  }

  get totalEmployedBBLLevel2() {
    if (!this.employedBBLLevel2Year1 || !this.employedBBLLevel2Year2) {
      return undefined
    }
    return this.employedBBLLevel2Year1 + this.employedBBLLevel2Year2
  }

  get totalEmployedBBL() {
    return (
      (this.totalEmployedBBLLevel5 || 0) +
      (this.totalEmployedBBLLevel4 || 0) +
      (this.totalEmployedBBLLevel3 || 0) +
      (this.totalEmployedBBLLevel2 || 0) +
      (this.employedBBLLevel2ShortenedYear1 || 0) +
      (this.employedBBLLevel1Year1 || 0)
    )
  }

  // Result object for BBL employed
  get employedBBL() {
    return {
      level5: {
        year4: this.employedBBLLevel5Year4 ?? 0,
        year3: this.employedBBLLevel5Year3 ?? 0,
        total: this.totalEmployedBBLLevel5 ?? 0,
      },
      level4: {
        year4: this.employedBBLLevel4Year4 ?? 0,
        year3: this.employedBBLLevel4Year3 ?? 0,
        year2: this.employedBBLLevel4Year2 ?? 0,
        year1: this.employedBBLLevel4Year1 ?? 0,
        total: this.totalEmployedBBLLevel4 ?? 0,
      },
      level3: {
        year3: this.employedBBLLevel3Year3 ?? 0,
        year2: this.employedBBLLevel3Year2 ?? 0,
        year1: this.employedBBLLevel3Year1 ?? 0,
        total: this.totalEmployedBBLLevel3 ?? 0,
      },
      level2: {
        year2: this.employedBBLLevel2Year2 ?? 0,
        year1: this.employedBBLLevel2Year1 ?? 0,
        total: this.totalEmployedBBLLevel2 ?? 0,
      },
      level2Shortened: {
        year1: this.employedBBLLevel2ShortenedYear1 ?? 0,
      },
      level1: {
        year1: this.employedBBLLevel1Year1 ?? 0,
      },
    }
  }

  // BOL Results calculations

  // Dropout rates

  get dropoutPerYearBOLLevel5() {
    if (!this.employedBOLLevel5Year1 || !this.BOLoutflow.level5 || !this.constantsBOL) {
      return undefined
    }
    return (this.employedBOLLevel5Year1 - this.BOLoutflow.level5) / this.constantsBOL.Level5.Years
  }

  get dropoutPerYearBOLLevel4() {
    if (!this.employedBOLLevel4Year1 || !this.BOLoutflow.level4 || !this.constantsBOL) {
      return undefined
    }
    return (this.employedBOLLevel4Year1 - this.BOLoutflow.level4) / this.constantsBOL.Level4.Years
  }

  get dropoutPerYearBOLLevel3() {
    if (!this.employedBOLLevel3Year1 || !this.BOLoutflow.level3 || !this.constantsBOL) {
      return undefined
    }
    return (this.employedBOLLevel3Year1 - this.BOLoutflow.level3) / this.constantsBOL.Level3.Years
  }

  get dropoutPerYearBOLLevel2() {
    if (!this.employedBOLLevel2Year1 || !this.BOLoutflow.level2 || !this.constantsBOL) {
      return undefined
    }
    return (this.employedBOLLevel2Year1 - this.BOLoutflow.level2) / this.constantsBOL.Level2.Years
  }

  get dropoutPerYearBOLLevel2shortened() {
    if (!this.employedBOLLevel2ShortenedYear1 || !this.BOLoutflow.level2Shortened || !this.constantsBOL) {
      return undefined
    }
    return Math.round(
      (this.employedBOLLevel2ShortenedYear1 - this.BOLoutflow.level2Shortened) / this.constantsBOL.Level2.Years
    )
  }

  get dropoutPerYearBOLLevel1shortened() {
    if (!this.employedBOLLevel1Year1 || !this.BOLoutflow.level1 || !this.constantsBOL) {
      return undefined
    }
    return (this.employedBOLLevel1Year1 - this.BOLoutflow.level1) / this.constantsBOL.Level2.Years
  }

  // Number of BOL employed for different levels and years

  get employedBOLLevel5Year1() {
    return this.BOLoutflow.level5 && this.constantsBOL
      ? this.BOLoutflow.level5 / (this.constantsBOL.Level5.EducationYield * this.constantsBOL.Level5.SectorYield)
      : undefined
  }

  get employedBOLLevel5Year2() {
    return this.employedBOLLevel5Year1 && this.dropoutPerYearBOLLevel5
      ? this.employedBOLLevel5Year1 - this.dropoutPerYearBOLLevel5
      : undefined
  }

  get employedBOLLevel5Year3() {
    return this.employedBOLLevel5Year2 && this.dropoutPerYearBOLLevel5
      ? this.employedBOLLevel5Year2 - this.dropoutPerYearBOLLevel5
      : undefined
  }

  get employedBOLLevel5Year4() {
    return this.employedBOLLevel5Year3 && this.dropoutPerYearBOLLevel5
      ? this.employedBOLLevel5Year3 - this.dropoutPerYearBOLLevel5
      : undefined
  }

  get employedBOLLevel4Year1() {
    return this.BOLoutflow.level4 && this.constantsBOL
      ? this.BOLoutflow.level4 / (this.constantsBOL.Level4.EducationYield * this.constantsBOL.Level4.SectorYield)
      : undefined
  }

  get employedBOLLevel4Year2() {
    return this.employedBOLLevel4Year1 && this.dropoutPerYearBOLLevel4
      ? this.employedBOLLevel4Year1 - this.dropoutPerYearBOLLevel4
      : undefined
  }

  get employedBOLLevel4Year3() {
    return this.employedBOLLevel4Year2 && this.dropoutPerYearBOLLevel4
      ? this.employedBOLLevel4Year2 - this.dropoutPerYearBOLLevel4
      : undefined
  }

  get employedBOLLevel4Year4() {
    return this.employedBOLLevel4Year3 && this.dropoutPerYearBOLLevel4
      ? this.employedBOLLevel4Year3 - this.dropoutPerYearBOLLevel4
      : undefined
  }

  get employedBOLLevel3Year1() {
    return this.BOLoutflow.level3 && this.constantsBOL
      ? this.BOLoutflow.level3 / (this.constantsBOL.Level3.EducationYield * this.constantsBOL.Level3.SectorYield)
      : undefined
  }

  get employedBOLLevel3Year2() {
    return this.employedBOLLevel3Year1 && this.dropoutPerYearBOLLevel3
      ? this.employedBOLLevel3Year1 - this.dropoutPerYearBOLLevel3
      : undefined
  }

  get employedBOLLevel3Year3() {
    return this.employedBOLLevel3Year2 && this.dropoutPerYearBOLLevel3
      ? this.employedBOLLevel3Year2 - this.dropoutPerYearBOLLevel3
      : undefined
  }

  get employedBOLLevel2Year1() {
    return this.BOLoutflow.level2 && this.constantsBOL
      ? this.BOLoutflow.level2 / (this.constantsBOL.Level2.EducationYield * this.constantsBOL.Level2.SectorYield)
      : undefined
  }

  get employedBOLLevel2Year2() {
    return this.employedBOLLevel2Year1 && this.dropoutPerYearBOLLevel2
      ? this.employedBOLLevel2Year1 - this.dropoutPerYearBOLLevel2
      : undefined
  }

  get employedBOLLevel2ShortenedYear1() {
    return this.BOLoutflow.level2Shortened && this.constantsBOL
      ? this.BOLoutflow.level2Shortened /
          (this.constantsBOL.Level2shortened.EducationYield * this.constantsBOL.Level2shortened.SectorYield)
      : undefined
  }

  get employedBOLLevel1Year1() {
    return this.BOLoutflow.level1 && this.constantsBOL
      ? this.BOLoutflow.level1 / (this.constantsBOL.Level1.EducationYield * this.constantsBOL.Level1.SectorYield)
      : undefined
  }

  // First two years of level 5 BBL are fulltime and are thus considered under BOL
  get totalEmployedBBLLevel5BaseYears() {
    if (!this.employedBBLLevel5Year1 || !this.employedBBLLevel5Year2) {
      return undefined
    }
    return this.employedBBLLevel5Year1 + this.employedBBLLevel5Year2
  }

  get totalEmployedBOLLevel5() {
    if (
      !this.employedBOLLevel5Year1 ||
      !this.employedBOLLevel5Year2 ||
      !this.employedBOLLevel5Year3 ||
      !this.employedBOLLevel5Year4
    ) {
      return undefined
    }
    return (
      this.employedBOLLevel5Year1 +
      this.employedBOLLevel5Year2 +
      this.employedBOLLevel5Year3 +
      this.employedBOLLevel5Year4
    )
  }

  get totalEmployedBOLLevel4() {
    if (
      !this.employedBOLLevel4Year1 ||
      !this.employedBOLLevel4Year2 ||
      !this.employedBOLLevel4Year3 ||
      !this.employedBOLLevel4Year4
    ) {
      return undefined
    }
    return (
      this.employedBOLLevel4Year1 +
      this.employedBOLLevel4Year2 +
      this.employedBOLLevel4Year3 +
      this.employedBOLLevel4Year4
    )
  }

  get totalEmployedBOLLevel3() {
    if (!this.employedBOLLevel3Year1 || !this.employedBOLLevel3Year2 || !this.employedBOLLevel3Year3) return undefined
    return this.employedBOLLevel3Year1 + this.employedBOLLevel3Year2 + this.employedBOLLevel3Year3
  }

  get totalEmployedBOLLevel2() {
    if (!this.employedBOLLevel2Year1 || !this.employedBOLLevel2Year2) return undefined
    return this.employedBOLLevel2Year1 + this.employedBOLLevel2Year2
  }

  get totalEmployedBOL() {
    return (
      (this.totalEmployedBBLLevel5BaseYears || 0) +
      (this.totalEmployedBOLLevel5 || 0) +
      (this.totalEmployedBOLLevel4 || 0) +
      (this.totalEmployedBOLLevel3 || 0) +
      (this.totalEmployedBOLLevel2 || 0) +
      (this.employedBOLLevel2ShortenedYear1 || 0) +
      (this.employedBOLLevel1Year1 || 0)
    )
  }

  // Result object for BOL employed
  get employedBOL() {
    return {
      // First two years of level 5 Duaal are full time
      level5BBL: {
        year2: this.employedBBLLevel5Year2 ?? 0,
        year1: this.employedBBLLevel5Year1 ?? 0,
        total: this.totalEmployedBBLLevel5BaseYears ?? 0,
      },
      level5: {
        year4: this.employedBOLLevel5Year4 ?? 0,
        year3: this.employedBOLLevel5Year3 ?? 0,
        year2: this.employedBOLLevel5Year2 ?? 0,
        year1: this.employedBOLLevel5Year1 ?? 0,
        total: this.totalEmployedBOLLevel5 ?? 0,
        totalIncludingBaseYears: (this.totalEmployedBOLLevel5 || 0) + (this.totalEmployedBBLLevel5BaseYears || 0),
      },
      level4: {
        year4: this.employedBOLLevel4Year4 ?? 0,
        year3: this.employedBOLLevel4Year3 ?? 0,
        year2: this.employedBOLLevel4Year2 ?? 0,
        year1: this.employedBOLLevel4Year1 ?? 0,
        total: this.totalEmployedBOLLevel4 ?? 0,
      },
      level3: {
        year3: this.employedBOLLevel3Year3 ?? 0,
        year2: this.employedBOLLevel3Year2 ?? 0,
        year1: this.employedBOLLevel3Year1 ?? 0,
        total: this.totalEmployedBOLLevel3 ?? 0,
      },
      level2: {
        year2: this.employedBOLLevel2Year2 ?? 0,
        year1: this.employedBOLLevel2Year1 ?? 0,
        total: this.totalEmployedBOLLevel2 ?? 0,
      },
      level2Shortened: {
        year1: this.employedBOLLevel2ShortenedYear1 ?? 0,
      },
      level1: {
        year1: this.employedBOLLevel1Year1 ?? 0,
      },
    }
  }

  get fulltimePOK() {
    return {
      level5BBL:
        this.employedBOL.level5BBL.total && this.constantsBOL
          ? this.employedBOL.level5BBL.total * this.constantsBOL.Level5.FractionBpv
          : 0,
      level5:
        this.employedBOL.level5.total && this.constantsBOL
          ? this.employedBOL.level5.total * this.constantsBOL.Level5.FractionBpv
          : 0,
      level4:
        this.employedBOL.level4.total && this.constantsBOL
          ? this.employedBOL.level4.total * this.constantsBOL.Level4.FractionBpv
          : 0,
      level3:
        this.employedBOL.level3.total && this.constantsBOL
          ? this.employedBOL.level3.total * this.constantsBOL.Level3.FractionBpv
          : 0,
      level2:
        this.employedBOL.level2.total && this.constantsBOL
          ? this.employedBOL.level2.total * this.constantsBOL.Level2.FractionBpv
          : 0,
      level2Shortened:
        this.employedBOL.level2Shortened.year1 && this.constantsBOL
          ? this.employedBOL.level2Shortened.year1 * this.constantsBOL.Level2shortened.FractionBpv
          : 0,
      level1:
        this.employedBOL.level1.year1 && this.constantsBOL
          ? this.employedBOL.level1.year1 * this.constantsBOL.Level1.FractionBpv
          : 0,
    }
  }

  get totalFullTimePOK() {
    return Object.values(this.fulltimePOK).reduce((sum, value) => sum + (value || 0), 0)
  }
}
