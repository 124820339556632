import { styled } from '@styles/config'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import logo from '../../public/img/wgv-logo.png'

const LogoWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginLeft: '120px',
})

export const Logo = () => {
  const { t } = useTranslation('common')
  return (
    <LogoWrapper>
      <Image alt='wgv logo' src={logo} height='54px' width='112px' />
    </LogoWrapper>
  )
}
