import gql from 'graphql-tag'

export const CREATE_COSTS_CALCULATION_FORM = gql`
  mutation CreateCostsCalculationForm {
    createCostsCalculationForm {
      id
      currentUrl
      organisationName
      dataYear
      averageInternshipWeeks
      totalExtraHoursPerWeek
      totalPaidSchoolHoursPerWeek
      bblInternshipWeeks
      availableHoursPercentage
      bblWorkInstructorIndirectWork
      bblPracticeInstructorIndirectWork
      bolWorkInstructorIndirectWork
      bolPracticeInstructorIndirectWork
      workInstructorAverageSalary
      workInstructorSocialSecurity
      practiceInstructorAverageSalary
      practiceInstructorSocialSecurity
      bblAverageSalary
      bblSocialSecurity
      bolHourlySalary
      bolOtherCosts
      bblLevel34Subsidy
      bblLevel12Subsidy
      hboSubsidy
      studyMaterialCosts
      travelCosts
      otherIncome
      bolEmployeeProductivity
      bblEmployeeAvailabilities {
        introduction {
          workInstructor
          practiceInstructor
        }
        planning {
          workInstructor
          practiceInstructor
        }
        progress {
          workInstructor
          practiceInstructor
        }
        intermediate {
          workInstructor
          practiceInstructor
        }
        endEvaluation {
          workInstructor
          practiceInstructor
        }
      }
      bolEmployeeAvailabilities {
        introduction {
          workInstructor
          practiceInstructor
        }
        planning {
          workInstructor
          practiceInstructor
        }
        progress {
          workInstructor
          practiceInstructor
        }
        intermediate {
          workInstructor
          practiceInstructor
        }
        endEvaluation {
          workInstructor
          practiceInstructor
        }
      }
      bolMeetingSchedules {
        introduction {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
        planning {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
        progress {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
        intermediate {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
        endEvaluation {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
      }
      bblMeetingSchedules {
        introduction {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
        planning {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
        progress {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
        intermediate {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
        endEvaluation {
          meetingsPerStudent
          meetingDuration
          studentsPerMeeting
        }
      }
      bblStudents {
        hboNursing {
          amount
          hoursPerWeek
        }
        hboOther {
          amount
          hoursPerWeek
        }
        mboN4NursingAssistant {
          amount
          hoursPerWeek
        }
        mboN4Other {
          amount
          hoursPerWeek
        }
        mboN3Caring {
          amount
          hoursPerWeek
        }
        mboN3Other {
          amount
          hoursPerWeek
        }
        mboN2 {
          amount
          hoursPerWeek
        }
        mboN1 {
          amount
          hoursPerWeek
        }
      }
      bolStudents {
        hboNursing {
          amount
          hoursPerWeek
          internshipWeeks
        }
        hboOther {
          amount
          hoursPerWeek
          internshipWeeks
        }
        mboN4NursingAssistant {
          amount
          hoursPerWeek
          internshipWeeks
        }
        mboN4Other {
          amount
          hoursPerWeek
          internshipWeeks
        }
        mboN3Caring {
          amount
          hoursPerWeek
          internshipWeeks
        }
        mboN3Other {
          amount
          hoursPerWeek
          internshipWeeks
        }
        mboN2 {
          amount
          hoursPerWeek
          internshipWeeks
        }
        mboN1 {
          amount
          hoursPerWeek
          internshipWeeks
        }
      }
    }
  }
`
