import { createStitches } from '@stitches/react'


// Design system definition for the application's styles (similar to Tailwind's config)
// See https://stitches.dev/docs/tokens for the available settings
export const {styled, css, createTheme, getCssText, globalCss} = createStitches({
  theme: {
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      gray100: '#FDFEFF',
      gray200: '#F3F5F8',
      gray300: '#E6ECF3',
      gray400: '#D8D8D8',
      gray500: '#8D8D8D',
      gray800: '#3B4956',
      blue200: '#E5F1FF',
      blue300: '#D6E9FF',
      blue400: '#C5E0FF',
      blue500: '#009BE2',
      blue600: '#009DE8',
      blue800: '#3B6CCC',
      redHover: '#FF0000',
      warning: '#FF9900',
      primaryMagenta: '#DB0F68',
      primaryMagenta10: '#DB0F681A',
      primaryMagenta20: '#DB0F6833',
      primaryPurple: '#481087',
      primaryPurple10: '#4810871A',
      primaryPurple20: '#48108733',
      primaryPurpleDark: '#371A57',
      primaryBlack: '#000000',
      primaryBlue:'#009DE2',
      secondaryBlue: '#EFEFFA',
      secondaryMagenta: '#9F1081',
      secondaryPurple: '#693CC4',
      secondaryBlack: '#2C1941',
      primaryGradient: 'linear-gradient(90deg, #DB0F68 0%, #481087 100%)',
      primaryGradientLight: 'linear-gradient(90deg, #DB0F681A 0%, #4810871A 100%)',
    },
    space: {
      1: '5px',
      2: '10px',
      3: '15px',
    },
    fontSizes: {
      xsmall: '0.857rem',
      small: '1rem',
      medium: '1.143rem',
      large: '1.5rem',
      xlarge: '1.714rem',
      xxlarge: '3.429rem',
    },
    fonts: {
      inter: 'Inter, system-ui, sans-serif',
    },
    fontWeights: {},
    lineHeights: {
      small: '16.41px',
      medium: '20.11px',
    },
    letterSpacings: {},
    sizes: {},
    borderWidths: {},
    borderStyles: {},
    radii: {
      radius: '5px'
    },
    shadows: {
      card: '0px 2px 4px #E2E7EC'
    },
    zIndices: {},
    transitions: {},
  },
})
