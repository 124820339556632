import { Modal } from '@/Modal/Modal'
import { useStore } from '@stores/index'
import useTranslation from 'next-translate/useTranslation'
import router from 'next/router'
import React from 'react'

interface NewCostsCalculationModal {
  guest: boolean
}

export const NewCostsCalculationModal = ({ guest }: NewCostsCalculationModal) => {
  const { t } = useTranslation('common')
  const { formStore, costStore } = useStore()

  const newCostsCalculation = () => {
    costStore.createNewCostsCalculationForm()
    formStore.setNewCalculationModalIsOpen()
    router.push('/costs-calculation/basic-information/internship-duration')
  }

  return (
    <Modal
      confirm={newCostsCalculation}
      close={formStore.setNewCalculationModalIsOpen}
      title={t('modal.new-calculation-confirmation-title')}
      text={t('modal.new-calculation-warning-2') + (guest ? t('modal.new-calculation-warning-guest') : '')}
    >{guest ?
      (<span><a href={process.env.NEXT_PUBLIC_WGV_EMPLOYER_MEETINSTRUMENTEN_URL!}>{t('modal.login')}</a>.</span>)
      :
      null
    }</Modal>
  )
}
