import { useMemo } from 'react'

import { RootStore } from './root'
import { enableStaticRendering } from 'mobx-react'

let store: RootStore

function initializeStore() {
  const _store = store ?? new RootStore()
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    enableStaticRendering(true)
    return _store
  }
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore() {
  return useMemo(() => initializeStore(), [])
}
