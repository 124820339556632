import { styled } from '@styles/config'

export const ContentWrapper = styled('div', {
  gridRow: '2 / -1',
  gridColumn: '1',
  display: 'flex',
  height: 'auto',
  width: '100%',
  marginTop: '94px',
  justifyContent: 'center',
  gap: '10px',
})
