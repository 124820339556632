import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { getApolloClient } from '@services/apollo'
import { autorun, makeAutoObservable, reaction, runInAction } from 'mobx'
import { clearPersistedStore, hydrateStore, makePersistable, pausePersisting } from 'mobx-persist-store'
import { CREATE_COSTS_CALCULATION_FORM } from 'mutations/createCostsCalculationForm'
import { FormStore } from './formStore'
import { RootStore } from '@stores/root'
import { AuthStore } from '@stores/authStore'
import { DEFAULT_COSTS_CALCULATION_FORM } from '../queries/defaultCostsCalculationForm'
import { MY_MOST_RECENT_COSTS_CALCULATION } from '../queries/myMostRecentCostsCalculation'
import { UPDATE_COSTS_CALCULATION_FORM } from '../mutations/updateCostsCalculationForm'
import { VisitedStore } from '@stores/visitedStore'

const PERSIST_CHANGES_DELAY = 2000

interface LevelObjectBbl {
  amount?: number | null
  hoursPerWeek?: number | null
}

interface LevelObjectBol {
  amount?: number | null
  hoursPerWeek?: number | null
  internshipWeeks?: number | null
}

interface BblStudents {
  hboNursing: LevelObjectBbl
  hboOther: LevelObjectBbl
  mboN4NursingAssistant: LevelObjectBbl
  mboN4Other: LevelObjectBbl
  mboN3Caring: LevelObjectBbl
  mboN3Other: LevelObjectBbl
  mboN2: LevelObjectBbl
  mboN1: LevelObjectBbl
}

interface BolStudents {
  hboNursing: LevelObjectBol
  hboOther: LevelObjectBol
  mboN4NursingAssistant: LevelObjectBol
  mboN4Other: LevelObjectBol
  mboN3Caring: LevelObjectBol
  mboN3Other: LevelObjectBol
  mboN2: LevelObjectBol
  mboN1: LevelObjectBol
}

interface MeetingObject {
  meetingsPerStudent: number | null
  meetingDuration: number | null
  studentsPerMeeting: number | null
}

interface MeetingSchedules {
  introduction: MeetingObject
  planning: MeetingObject
  progress: MeetingObject
  intermediate: MeetingObject
  endEvaluation: MeetingObject
}

interface MeetingParticipants {
  workInstructor?: number | null
  practiceInstructor?: number | null
}

interface EmployeeAvailabilities {
  introduction: MeetingParticipants
  planning: MeetingParticipants
  progress: MeetingParticipants
  intermediate: MeetingParticipants
  endEvaluation: MeetingParticipants
}

interface fundingPerFTE {
  hboNursing: number
  hboOther: number
  mboN4NursingAssistant: number
  mboN4Other: number
  mboN3Caring: number
  mboN3Other: number
  mboN2: number
  mboN1: number
}

export enum StepStatus {
  NONE,
  WARNING,
  COMPLETED,
}

export class CostStore {
  client: ApolloClient<NormalizedCacheObject>
  formStore: FormStore
  authStore: AuthStore
  visitedStore: VisitedStore

  constructor(rootStore: RootStore) {
    this.client = getApolloClient()
    this.formStore = rootStore.formStore
    this.authStore = rootStore.authStore
    this.visitedStore = rootStore.visitedStore
    makeAutoObservable(this, { client: false })
    if (typeof window !== 'undefined') {
      makePersistable(this, {
        name: 'cost-form-store',
        properties: [
          'id',
          'currentUrl',
          'averageInternshipWeeks',
          'totalExtraHoursPerWeek',
          'totalPaidSchoolHoursPerWeek',
          'bblStudents',
          'bolStudents',
          'bblMeetingSchedules',
          'bolMeetingSchedules',
          'bblEmployeeAvailabilities',
          'bolEmployeeAvailabilities',
          'availableHoursPercentage',
          'bblWorkInstructorIndirectWork',
          'bblPracticeInstructorIndirectWork',
          'bolWorkInstructorIndirectWork',
          'bolPracticeInstructorIndirectWork',
          'workInstructorAverageSalary',
          'workInstructorSocialSecurity',
          'practiceInstructorAverageSalary',
          'practiceInstructorSocialSecurity',
          'bblLevel34Subsidy',
          'bblLevel12Subsidy',
          'hboSubsidy',
          'studyMaterialCosts',
          'travelCosts',
          'bblAverageSalary',
          'bblSocialSecurity',
          'bolHourlySalary',
          'bolOtherCosts',
          'bolEmployeeProductivity',
        ],
        storage: window.localStorage,
      }, { delay: PERSIST_CHANGES_DELAY }).then(() => {
        this.fetchFormData()
      })
    }

    reaction(
      () =>
        JSON.stringify(this.getFieldsToStore()),
      (_) => {
        if (this.valid && this.authStore.hasToken) {
          this.client
            .mutate({
              mutation: UPDATE_COSTS_CALCULATION_FORM,
              variables: {
                input: this.getFieldsToStore(),
              },
            })
            .catch((error) => console.error(error))
        }
      },
      { delay: PERSIST_CHANGES_DELAY }
    )

    autorun(() => {
      this.setBolMeetingsSchedules(
        'progress',
        'meetingsPerStudent',
        Math.round(this.numberOfProgressMeetingsPerStudentBol)
      )
    })
  }

  fetchFormData = async () => {
    if (typeof window === 'undefined') {
      return
    }
    if (this.authStore.hasToken) {
      pausePersisting(this)
      this.reset()
      try {
        const response = await this.client.query({
          query: MY_MOST_RECENT_COSTS_CALCULATION,
        })
        if (response.data.myCostsCalculationForms.items.length > 0) {
          const mostRecentCostsCalculation = response.data.myCostsCalculationForms.items[0]
          this.setFieldsWithDataObject(mostRecentCostsCalculation)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  createNewCostsCalculationForm = async () => {
    if (typeof window === 'undefined') {
      return
    }
    try {
      if (this.authStore.hasToken) {
        const response = await this.client.mutate({
          mutation: CREATE_COSTS_CALCULATION_FORM,
        })
        this.setFieldsWithDataObject(response.data.createCostsCalculationForm)
      } else {
        const response = await this.client.query({
          query: DEFAULT_COSTS_CALCULATION_FORM,
        })
        this.setFieldsWithDataObject(response.data.defaultCostsCalculationForm)
      }
    } catch (error) {
      console.error(error)
    }
    this.visitedStore.clearVisitedCosts()
  }

  getFieldsToStore() {
    return {
      id: this.id,
      currentUrl: this.currentUrl,
      averageInternshipWeeks: this.averageInternshipWeeks,
      totalExtraHoursPerWeek: this.totalExtraHoursPerWeek,
      totalPaidSchoolHoursPerWeek: this.totalPaidSchoolHoursPerWeek,
      bblStudents: this.bblStudents,
      bolStudents: this.bolStudents,
      bblMeetingSchedules: this.bblMeetingSchedules,
      bolMeetingSchedules: this.bolMeetingSchedules,
      bblEmployeeAvailabilities: this.bblEmployeeAvailabilities,
      bolEmployeeAvailabilities: this.bolEmployeeAvailabilities,
      availableHoursPercentage: this.availableHoursPercentage,
      bblWorkInstructorIndirectWork: this.bblWorkInstructorIndirectWork,
      bblPracticeInstructorIndirectWork: this.bblPracticeInstructorIndirectWork,
      bolWorkInstructorIndirectWork: this.bolWorkInstructorIndirectWork,
      bolPracticeInstructorIndirectWork: this.bolPracticeInstructorIndirectWork,
      workInstructorAverageSalary: this.workInstructorAverageSalary,
      workInstructorSocialSecurity: this.workInstructorSocialSecurity,
      practiceInstructorAverageSalary: this.practiceInstructorAverageSalary,
      practiceInstructorSocialSecurity: this.practiceInstructorSocialSecurity,
      bblLevel34Subsidy: this.bblLevel34Subsidy,
      bblLevel12Subsidy: this.bblLevel12Subsidy,
      hboSubsidy: this.hboSubsidy,
      studyMaterialCosts: this.studyMaterialCosts,
      travelCosts: this.travelCosts,
      bblAverageSalary: this.bblAverageSalary,
      bblSocialSecurity: this.bblSocialSecurity,
      bolHourlySalary: this.bolHourlySalary,
      bolOtherCosts: this.bolOtherCosts,
      bolEmployeeProductivity: this.bolEmployeeProductivity,
    }
  }

  setFieldsWithDataObject = (data: any) => {
    runInAction(() => {
      this.id = data.id
      this.averageInternshipWeeks = data.averageInternshipWeeks
      this.totalExtraHoursPerWeek = data.totalExtraHoursPerWeek
      this.totalPaidSchoolHoursPerWeek = data.totalPaidSchoolHoursPerWeek
      this.bblStudents = data.bblStudents
      this.bolStudents = data.bolStudents
      this.bblMeetingSchedules = data.bblMeetingSchedules
      this.bolMeetingSchedules = data.bolMeetingSchedules
      this.bblEmployeeAvailabilities = data.bblEmployeeAvailabilities
      this.bolEmployeeAvailabilities = data.bolEmployeeAvailabilities
      this.availableHoursPercentage = data.availableHoursPercentage
      this.bblWorkInstructorIndirectWork = data.bblWorkInstructorIndirectWork
      this.bblPracticeInstructorIndirectWork = data.bblPracticeInstructorIndirectWork
      this.bolWorkInstructorIndirectWork = data.bolWorkInstructorIndirectWork
      this.bolPracticeInstructorIndirectWork = data.bolPracticeInstructorIndirectWork
      this.workInstructorAverageSalary = data.workInstructorAverageSalary
      this.workInstructorSocialSecurity = data.workInstructorSocialSecurity
      this.practiceInstructorAverageSalary = data.practiceInstructorAverageSalary
      this.practiceInstructorSocialSecurity = data.practiceInstructorSocialSecurity
      this.bblLevel34Subsidy = data.bblLevel34Subsidy
      this.bblLevel12Subsidy = data.bblLevel12Subsidy
      this.hboSubsidy = data.hboSubsidy
      this.studyMaterialCosts = data.studyMaterialCosts
      this.travelCosts = data.travelCosts
      this.bblAverageSalary = data.bblAverageSalary
      this.bblSocialSecurity = data.bblSocialSecurity
      this.bblInternshipWeeks = data.bblInternshipWeeks
      this.bolHourlySalary = data.bolHourlySalary
      this.bolOtherCosts = data.bolOtherCosts
      this.bolEmployeeProductivity = data.bolEmployeeProductivity
      this.bblMeetingSchedules.progress.meetingsPerStudent = data.averageInternshipWeeks
    })
  }

  removeLocal() {
    return clearPersistedStore(this)
  }

  async moveLocal() {
    try {
      const response = await this.client.mutate({
        mutation: CREATE_COSTS_CALCULATION_FORM,
      })
      await hydrateStore(this).then(() => clearPersistedStore(this))
      this.setId(response.data.createCostsCalculationForm.id)
    } catch (error) {
      console.error(error)
    }
  }

  id: number | null = null
  currentUrl: string | null = null

  steps = {
    averageInternshipWeeks: () => {
      if (!this.validAverageInternshipWeeks) {
        return StepStatus.WARNING
      }
      if (this.averageInternshipWeeks && this.visitedStore.visitedCosts.includes('averageInternshipWeeks')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },

    totalExtraHoursPerWeek: () => {
      if (!this.validTotalExtraHoursPerWeek) {
        return StepStatus.WARNING
      }
      if (this.totalExtraHoursPerWeek && this.visitedStore.visitedCosts.includes('totalExtraHoursPerWeek')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },

    totalPaidSchoolHoursPerWeek: () => {
      if (!this.validTotalPaidSchoolHoursPerWeek) {
        return StepStatus.WARNING
      }
      if (this.totalPaidSchoolHoursPerWeek && this.visitedStore.visitedCosts.includes('totalPaidSchoolHoursPerWeek')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblStudents: () => {
      if (!(this.validBblStudentsAmounts && this.validBblStudentsHours)) {
        return StepStatus.WARNING
      }
      if (this.completeBblStudents && this.visitedStore.visitedCosts.includes('bblStudents')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolStudents: () => {
      if (!(this.validBolStudentsAmounts && this.validBolStudentsHours)) {
        return StepStatus.WARNING
      }
      if (this.completeBolStudents && this.visitedStore.visitedCosts.includes('bolStudents')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblMeetingSchedules: () => {
      if (!this.validBblMeetingSchedules) {
        return StepStatus.WARNING
      }
      if (this.completeBblMeetingSchedules && this.visitedStore.visitedCosts.includes('bblMeetingSchedules')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolMeetingSchedules: () => {
      if (!this.validBolMeetingSchedules) {
        return StepStatus.WARNING
      }
      if (this.completeBolMeetingSchedules && this.visitedStore.visitedCosts.includes('bolMeetingSchedules')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblIntroductionMeeting: () => {
      if (this.visitedStore.visitedCosts.includes('bblIntroductionMeeting')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblPlanningMeeting: () => {
      if (this.visitedStore.visitedCosts.includes('bblPlanningMeeting')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblProgressMeeting: () => {
      if (this.visitedStore.visitedCosts.includes('bblProgressMeeting')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblIntermediateEvaluation: () => {
      if (this.visitedStore.visitedCosts.includes('bblIntermediateEvaluation')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblEndEvaluation: () => {
      if (this.visitedStore.visitedCosts.includes('bblEndEvaluation')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },

    bolIntroductionMeeting: () => {
      if (this.visitedStore.visitedCosts.includes('bolIntroductionMeeting')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolPlanningMeeting: () => {
      if (this.visitedStore.visitedCosts.includes('bolPlanningMeeting')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolProgressMeeting: () => {
      if (this.visitedStore.visitedCosts.includes('bolProgressMeeting')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolIntermediateEvaluation: () => {
      if (this.visitedStore.visitedCosts.includes('bolIntermediateEvaluation')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolEndEvaluation: () => {
      if (this.visitedStore.visitedCosts.includes('bolEndEvaluation')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },

    availableHoursPercentage: () => {
      if (!this.validAvailableHoursPercentage) {
        return StepStatus.WARNING
      }
      if (this.availableHoursPercentage && this.visitedStore.visitedCosts.includes('availableHoursPercentage')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblWorkInstructorIndirectWork: () => {
      if (!this.validBblWorkInstructorIndirectWork) {
        return StepStatus.WARNING
      }
      if (this.bblWorkInstructorIndirectWork && this.visitedStore.visitedCosts.includes('bblWorkInstructorIndirectWork')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblPracticeInstructorIndirectWork: () => {
      if (!this.validBblPracticeInstructorIndirectWork) {
        return StepStatus.WARNING
      }
      if (
        this.bblPracticeInstructorIndirectWork &&
        this.visitedStore.visitedCosts.includes('bblPracticeInstructorIndirectWork')
      ) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolWorkInstructorIndirectWork: () => {
      if (!this.validBolWorkInstructorIndirectWork) {
        return StepStatus.WARNING
      }
      if (this.bolWorkInstructorIndirectWork && this.visitedStore.visitedCosts.includes('bolWorkInstructorIndirectWork')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolPracticeInstructorIndirectWork: () => {
      if (!this.validBolPracticeInstructorIndirectWork) {
        return StepStatus.WARNING
      }
      if (
        this.bolPracticeInstructorIndirectWork &&
        this.visitedStore.visitedCosts.includes('bolPracticeInstructorIndirectWork')
      ) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },

    workInstructorAverageSalary: () => {
      if (!this.validWorkInstructorAverageSalary) {
        return StepStatus.WARNING
      }
      if (this.workInstructorAverageSalary && this.visitedStore.visitedCosts.includes('workInstructorAverageSalary')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    workInstructorSocialSecurity: () => {
      if (!this.validWorkInstructorSocialSecurity) {
        return StepStatus.WARNING
      }
      if (this.workInstructorSocialSecurity && this.visitedStore.visitedCosts.includes('workInstructorSocialSecurity')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    practiceInstructorAverageSalary: () => {
      if (!this.validPracticeInstructorAverageSalary) {
        return StepStatus.WARNING
      }
      if (this.practiceInstructorAverageSalary && this.visitedStore.visitedCosts.includes('practiceInstructorAverageSalary')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    practiceInstructorSocialSecurity: () => {
      if (!this.validPracticeInstructorSocialSecurity) {
        return StepStatus.WARNING
      }
      if (
        this.practiceInstructorSocialSecurity &&
        this.visitedStore.visitedCosts.includes('practiceInstructorSocialSecurity')
      ) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },

    educationCosts: () => {
      if (!this.validEducationCosts) {
        return StepStatus.WARNING
      }
      if (this.completeEducationCosts && this.visitedStore.visitedCosts.includes('educationCosts')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },

    bblAverageSalary: () => {
      if (!this.validBblAverageSalary) {
        return StepStatus.WARNING
      }
      if (this.bblAverageSalary && this.visitedStore.visitedCosts.includes('bblAverageSalary')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bblSocialSecurity: () => {
      if (!this.validBblSocialSecurity) {
        return StepStatus.WARNING
      }
      if (this.bblSocialSecurity && this.visitedStore.visitedCosts.includes('bblSocialSecurity')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolHourlySalary: () => {
      if (!this.validBolHourlySalary) {
        return StepStatus.WARNING
      }
      if (this.bolHourlySalary && this.visitedStore.visitedCosts.includes('bolHourlySalary')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolOtherCosts: () => {
      if (!this.validBolOtherCosts) {
        return StepStatus.WARNING
      }
      if (this.bolOtherCosts && this.visitedStore.visitedCosts.includes('bolOtherCosts')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
    bolEmployeeProductivity: () => {
      if (!this.validBolEmployeeProductivity) {
        return StepStatus.WARNING
      }
      if (this.bolEmployeeProductivity && this.visitedStore.visitedCosts.includes('bolEmployeeProductivity')) {
        return StepStatus.COMPLETED
      }
      return StepStatus.NONE
    },
  }

  get progress() {
    const stepStatuses = Object.values(this.steps)
    return (
      (stepStatuses.filter((stepStatus) => stepStatus() === StepStatus.COMPLETED).length / stepStatuses.length) * 100
    )
  }

  averageInternshipWeeks: number | null = null
  totalExtraHoursPerWeek: number | null = null
  totalPaidSchoolHoursPerWeek: number | null = null
  bblStudents: BblStudents = {
    hboNursing: { amount: null, hoursPerWeek: null },
    hboOther: { amount: null, hoursPerWeek: null },
    mboN4NursingAssistant: { amount: null, hoursPerWeek: null },
    mboN4Other: { amount: null, hoursPerWeek: null },
    mboN3Caring: { amount: null, hoursPerWeek: null },
    mboN3Other: { amount: null, hoursPerWeek: null },
    mboN2: { amount: null, hoursPerWeek: null },
    mboN1: { amount: null, hoursPerWeek: null },
  }
  bolStudents: BolStudents = {
    hboNursing: { amount: null, hoursPerWeek: null, internshipWeeks: null },
    hboOther: { amount: null, hoursPerWeek: null, internshipWeeks: null },
    mboN4NursingAssistant: { amount: null, hoursPerWeek: null, internshipWeeks: null },
    mboN4Other: { amount: null, hoursPerWeek: null, internshipWeeks: null },
    mboN3Caring: { amount: null, hoursPerWeek: null, internshipWeeks: null },
    mboN3Other: { amount: null, hoursPerWeek: null, internshipWeeks: null },
    mboN2: { amount: null, hoursPerWeek: null, internshipWeeks: null },
    mboN1: { amount: null, hoursPerWeek: null, internshipWeeks: null },
  }
  bblMeetingSchedules: MeetingSchedules = {
    introduction: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
    planning: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
    progress: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
    intermediate: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
    endEvaluation: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
  }
  bolMeetingSchedules: MeetingSchedules = {
    introduction: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
    planning: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
    progress: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
    intermediate: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
    endEvaluation: { meetingsPerStudent: null, meetingDuration: null, studentsPerMeeting: null },
  }
  bblEmployeeAvailabilities: EmployeeAvailabilities = {
    introduction: {},
    planning: {},
    progress: {},
    intermediate: {},
    endEvaluation: {},
  }
  bolEmployeeAvailabilities: EmployeeAvailabilities = {
    introduction: {},
    planning: {},
    progress: {},
    intermediate: {},
    endEvaluation: {},
  }
  availableHoursPercentage: number | null = null
  bblWorkInstructorIndirectWork: number | null = null
  bblPracticeInstructorIndirectWork: number | null = null
  bolWorkInstructorIndirectWork: number | null = null
  bolPracticeInstructorIndirectWork: number | null = null

  workInstructorAverageSalary: number | null = null
  workInstructorSocialSecurity: number | null = null
  practiceInstructorAverageSalary: number | null = null
  practiceInstructorSocialSecurity: number | null = null

  bblLevel34Subsidy: number | null = null
  bblLevel12Subsidy: number | null = null
  hboSubsidy: number | null = null
  studyMaterialCosts: number | null = null
  travelCosts: number | null = null
  bblAverageSalary: number | null = null
  bblSocialSecurity: number | null = null
  bolHourlySalary: number | null = null
  bolOtherCosts: number | null = null
  bolEmployeeProductivity: number | null = null
  bblInternshipWeeks: number | null = null
  fundingPerFTEbbl: fundingPerFTE = {
    hboNursing: 3050.89,
    hboOther: 1039.41,
    mboN4NursingAssistant: 2478.15,
    mboN4Other: 1116.39,
    mboN3Caring: 2479.15,
    mboN3Other: 1116.39,
    mboN2: 1116.39,
    mboN1: 1116.39,
  }
  fundingPerFTEbol: fundingPerFTE = {
    hboNursing: 3127.88,
    hboOther: 1116.39,
    mboN4NursingAssistant: 2401.16,
    mboN4Other: 1039.41,
    mboN3Caring: 2401.16,
    mboN3Other: 1039.41,
    mboN2: 1039.41,
    mboN1: 1039.41,
  }

  reset = () => {
    clearPersistedStore(this)
    this.visitedStore.clearVisitedCosts()
    this.id = null
    this.averageInternshipWeeks = 40
    this.totalExtraHoursPerWeek = null
    this.totalPaidSchoolHoursPerWeek = null
    this.availableHoursPercentage = 0.02
    this.bblWorkInstructorIndirectWork = null
    this.bblPracticeInstructorIndirectWork = null
    this.bolWorkInstructorIndirectWork = null
    this.bolPracticeInstructorIndirectWork = null
    this.workInstructorAverageSalary = 2136
    this.workInstructorSocialSecurity = 0.45
    this.practiceInstructorAverageSalary = 2257
    this.practiceInstructorSocialSecurity = 0.45
    this.bblLevel34Subsidy = 606
    this.bblLevel12Subsidy = 250
    this.hboSubsidy = 2143
    this.studyMaterialCosts = 360
    this.travelCosts = 400
    this.bblAverageSalary = 2018.81
    this.bblSocialSecurity = 0.45
    this.bolHourlySalary = null
    this.bolOtherCosts = 0.1
    this.bolEmployeeProductivity = 0.8
    this.bblInternshipWeeks = null
    this.bblStudents = {
      hboNursing: { amount: null, hoursPerWeek: null },
      hboOther: { amount: null, hoursPerWeek: null },
      mboN4NursingAssistant: { amount: null, hoursPerWeek: null },
      mboN4Other: { amount: null, hoursPerWeek: null },
      mboN3Caring: { amount: null, hoursPerWeek: null },
      mboN3Other: { amount: null, hoursPerWeek: null },
      mboN2: { amount: null, hoursPerWeek: null },
      mboN1: { amount: null, hoursPerWeek: null },
    }
    this.bolStudents = {
      hboNursing: { amount: null, hoursPerWeek: null, internshipWeeks: null },
      hboOther: { amount: null, hoursPerWeek: null, internshipWeeks: null },
      mboN4NursingAssistant: { amount: null, hoursPerWeek: null, internshipWeeks: null },
      mboN4Other: { amount: null, hoursPerWeek: null, internshipWeeks: null },
      mboN3Caring: { amount: null, hoursPerWeek: null, internshipWeeks: null },
      mboN3Other: { amount: null, hoursPerWeek: null, internshipWeeks: null },
      mboN2: { amount: null, hoursPerWeek: null, internshipWeeks: null },
      mboN1: { amount: null, hoursPerWeek: null, internshipWeeks: null },
    }
    this.bblMeetingSchedules = {
      introduction: { meetingsPerStudent: 1, meetingDuration: 60, studentsPerMeeting: 8 },
      planning: { meetingsPerStudent: 1, meetingDuration: 45, studentsPerMeeting: 1 },
      progress: { meetingsPerStudent: null, meetingDuration: 45, studentsPerMeeting: 1 },
      intermediate: { meetingsPerStudent: 1, meetingDuration: 45, studentsPerMeeting: 1 },
      endEvaluation: { meetingsPerStudent: 1, meetingDuration: 45, studentsPerMeeting: 1 },
    }
    this.bolMeetingSchedules = {
      introduction: { meetingsPerStudent: 1, meetingDuration: 90, studentsPerMeeting: 20 },
      planning: { meetingsPerStudent: 1, meetingDuration: 45, studentsPerMeeting: 1 },
      progress: { meetingsPerStudent: null, meetingDuration: 45, studentsPerMeeting: 1 },
      intermediate: { meetingsPerStudent: 1, meetingDuration: 45, studentsPerMeeting: 1 },
      endEvaluation: { meetingsPerStudent: 1, meetingDuration: 45, studentsPerMeeting: 1 },
    }
    Object.keys(this.bblEmployeeAvailabilities).forEach(
      (meeting: string) =>
        (this.bblEmployeeAvailabilities[meeting as keyof EmployeeAvailabilities] = {
          practiceInstructor: 0.5,
          workInstructor: 0.5,
        })
    )
    Object.keys(this.bolEmployeeAvailabilities).forEach(
      (meeting: string) =>
        (this.bolEmployeeAvailabilities[meeting as keyof EmployeeAvailabilities] = {
          practiceInstructor: 0.5,
          workInstructor: 0.5,
        })
    )
  }

  setId = (id: number) => (this.id = id)
  setCurrentUrl = (url: string) => (this.currentUrl = url)
  setAverageInternshipWeeks = (weeks: number) => {
    this.averageInternshipWeeks = weeks
    this.bblMeetingSchedules.progress.meetingsPerStudent = weeks
  }
  setTotalExtraHoursPerWeek = (hours: number) => (this.totalExtraHoursPerWeek = hours)
  setTotalPaidSchoolHoursPerWeek = (hours: number) => (this.totalPaidSchoolHoursPerWeek = hours)
  setAvailableHoursPercentage = (percentage: number) => (this.availableHoursPercentage = percentage / 100)
  setBblWorkInstructorIndirectWork = (percentage: number) => (this.bblWorkInstructorIndirectWork = percentage / 100)
  setBblPracticeInstructorIndirectWork = (percentage: number) =>
    (this.bblPracticeInstructorIndirectWork = percentage / 100)
  setBolWorkInstructorIndirectWork = (percentage: number) => (this.bolWorkInstructorIndirectWork = percentage / 100)
  setBolPracticeInstructorIndirectWork = (percentage: number) =>
    (this.bolPracticeInstructorIndirectWork = percentage / 100)
  setWorkInstructorAverageSalary = (salary: number) => (this.workInstructorAverageSalary = salary)
  setWorkInstructorSocialSecurity = (percentage: number) => (this.workInstructorSocialSecurity = percentage / 100)

  setPracticeInstructorAverageSalary = (salary: number) => (this.practiceInstructorAverageSalary = salary)
  setPracticeInstructorSocialSecurity = (percentage: number) =>
    (this.practiceInstructorSocialSecurity = percentage / 100)
  setBblLevel34Subsidy = (amount: number) => (this.bblLevel34Subsidy = amount)
  setBblLevel12Subsidy = (amount: number) => (this.bblLevel12Subsidy = amount)
  setBblHboSubsidy = (amount: number) => (this.hboSubsidy = amount)
  setStudyMaterialCosts = (amount: number) => (this.studyMaterialCosts = amount)
  setTravelCosts = (amount: number) => (this.travelCosts = amount)
  setBblAverageSalary = (amount: number) => (this.bblAverageSalary = amount)
  setBblSocialSecurity = (percentage: number) => (this.bblSocialSecurity = percentage / 100)
  setBolHourlySalary = (amount: number) => (this.bolHourlySalary = amount)
  setBolOtherCosts = (percentage: number) => (this.bolOtherCosts = percentage / 100)
  setBolEmployeeProductivity = (percentage: number) => (this.bolEmployeeProductivity = percentage / 100)

  setBblHboNursingAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBBL.level5.total)
    this.bblStudents.hboNursing.amount = amount
    if (this.bblStudents.hboNursing.amount > maxAmount) {
      this.bblStudents.hboNursing.amount = maxAmount
      this.bblStudents.hboOther.amount = 0
      return
    }
    this.bblStudents.hboOther.amount = maxAmount - amount
    if (!this.bblStudents.hboNursing.amount) {
      this.bblStudents.hboOther.amount = maxAmount
    }
  }

  setBblHboNursingHours = (hours: number) => (this.bblStudents.hboNursing.hoursPerWeek = hours)

  setBblHboOtherAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBBL.level5.total)
    this.bblStudents.hboOther.amount = amount
    if (this.bblStudents.hboOther.amount > maxAmount) {
      this.bblStudents.hboOther.amount = maxAmount
      this.bblStudents.hboNursing.amount = 0
      return
    }
    this.bblStudents.hboNursing.amount = maxAmount - amount
    if (!this.bblStudents.hboOther.amount) {
      this.bblStudents.hboNursing.amount = maxAmount
    }
  }

  setBblHboOtherHours = (hours: number) => (this.bblStudents.hboOther.hoursPerWeek = hours)

  setBblMboN4NursingAssistantAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBBL.level4.total)
    this.bblStudents.mboN4NursingAssistant.amount = amount
    if (this.bblStudents.mboN4NursingAssistant.amount > maxAmount) {
      this.bblStudents.mboN4NursingAssistant.amount = maxAmount
      this.bblStudents.mboN4Other.amount = 0
      return
    }
    this.bblStudents.mboN4Other.amount = maxAmount - amount
    if (!this.bblStudents.mboN4NursingAssistant.amount) {
      this.bblStudents.mboN4Other.amount = maxAmount
    }
  }

  setBblMboN4NursingAssistantHours = (hours: number) => (this.bblStudents.mboN4NursingAssistant.hoursPerWeek = hours)

  setBblMboN4OtherAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBBL.level4.total)
    this.bblStudents.mboN4Other.amount = amount
    if (this.bblStudents.mboN4Other.amount > maxAmount) {
      this.bblStudents.mboN4Other.amount = maxAmount
      this.bblStudents.mboN4NursingAssistant.amount = 0
      return
    }
    this.bblStudents.mboN4NursingAssistant.amount = maxAmount - amount
    if (!this.bblStudents.mboN4Other.amount) {
      this.bblStudents.mboN4NursingAssistant.amount = maxAmount
    }
  }

  setBblmboN4OtherHours = (hours: number) => (this.bblStudents.mboN4Other.hoursPerWeek = hours)

  setBblMboN3CaringAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBBL.level3.total)
    this.bblStudents.mboN3Caring.amount = amount
    if (this.bblStudents.mboN3Caring.amount > maxAmount) {
      this.bblStudents.mboN3Caring.amount = maxAmount
      this.bblStudents.mboN3Other.amount = 0
      return
    }
    this.bblStudents.mboN3Other.amount = maxAmount - amount
    if (!this.bblStudents.mboN3Caring.amount) {
      this.bblStudents.mboN3Other.amount = maxAmount
    }
  }

  setBblMboN3CaringHours = (hours: number) => (this.bblStudents.mboN3Caring.hoursPerWeek = hours)

  setBblMboN3OtherAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBBL.level3.total)
    this.bblStudents.mboN3Other.amount = amount
    if (this.bblStudents.mboN3Other.amount > maxAmount) {
      this.bblStudents.mboN3Other.amount = maxAmount
      this.bblStudents.mboN3Caring.amount = 0
      return
    }
    this.bblStudents.mboN3Caring.amount = maxAmount - amount
    if (!this.bblStudents.mboN3Other.amount) {
      this.bblStudents.mboN3Caring.amount = maxAmount
    }
  }

  setBblMboN3OtherHours = (hours: number) => (this.bblStudents.mboN3Other.hoursPerWeek = hours)
  setBblMboN2Amount = (amount: number) => (this.bblStudents.mboN2.amount = amount)
  setBblMboN2Hours = (hours: number) => (this.bblStudents.mboN2.hoursPerWeek = hours)
  setBblMboN1Amount = (amount: number) => (this.bblStudents.mboN1.amount = amount)
  setBblMboN1Hours = (hours: number) => (this.bblStudents.mboN1.hoursPerWeek = hours)

  setBolHboNursingAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBOL.level5.totalIncludingBaseYears)
    this.bolStudents.hboNursing.amount = amount
    if (this.bolStudents.hboNursing.amount > maxAmount) {
      this.bolStudents.hboNursing.amount = maxAmount
      this.bolStudents.hboOther.amount = 0
      return
    }
    this.bolStudents.hboOther.amount = maxAmount - amount
    if (!this.bolStudents.hboNursing.amount) {
      this.bolStudents.hboOther.amount = maxAmount
    }
  }

  setBolHboNursingInternshipWeeks = (weeks: number) => (this.bolStudents.hboNursing.internshipWeeks = weeks)

  setBolHboNursingHours = (hours: number) => (this.bolStudents.hboNursing.hoursPerWeek = hours)

  setBolHboOtherAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBOL.level5.total)
    this.bolStudents.hboOther.amount = amount
    if (this.bolStudents.hboOther.amount > maxAmount) {
      this.bolStudents.hboOther.amount = maxAmount
      this.bolStudents.hboNursing.amount = 0
      return
    }
    this.bolStudents.hboNursing.amount = maxAmount - amount
    if (!this.bolStudents.hboOther.amount) {
      this.bolStudents.hboNursing.amount = maxAmount
    }
  }

  setBolHboOtherInternshipWeeks = (weeks: number) => (this.bolStudents.hboOther.internshipWeeks = weeks)

  setBolHboOtherHours = (hours: number) => (this.bolStudents.hboOther.hoursPerWeek = hours)

  setBolMboN4NursingAssistantAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBOL.level4.total)
    this.bolStudents.mboN4NursingAssistant.amount = amount
    if (this.bolStudents.mboN4NursingAssistant.amount > maxAmount) {
      this.bolStudents.mboN4NursingAssistant.amount = maxAmount
      this.bolStudents.mboN4Other.amount = 0
      return
    }
    this.bolStudents.mboN4Other.amount = maxAmount - amount
    if (!this.bolStudents.mboN4NursingAssistant.amount) {
      this.bolStudents.mboN4Other.amount = maxAmount
    }
  }

  setBolMboN4NursingAssistantInternshipWeeks = (weeks: number) =>
    (this.bolStudents.mboN4NursingAssistant.internshipWeeks = weeks)

  setBolMboN4NursingAssistantHours = (hours: number) => (this.bolStudents.mboN4NursingAssistant.hoursPerWeek = hours)

  setBolMboN4OtherAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBOL.level4.total)
    this.bolStudents.mboN4Other.amount = amount
    if (this.bolStudents.mboN4Other.amount > maxAmount) {
      this.bolStudents.mboN4Other.amount = maxAmount
      this.bolStudents.mboN4NursingAssistant.amount = 0
      return
    }
    this.bolStudents.mboN4NursingAssistant.amount = maxAmount - amount
    if (!this.bolStudents.mboN4Other.amount) {
      this.bolStudents.mboN4NursingAssistant.amount = maxAmount
    }
  }

  setBolMboN4OtherInternshipWeeks = (weeks: number) => (this.bolStudents.mboN4Other.internshipWeeks = weeks)

  setBolMboN4OtherHours = (hours: number) => (this.bolStudents.mboN4Other.hoursPerWeek = hours)

  setBolMboN3CaringAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBOL.level3.total)
    this.bolStudents.mboN3Caring.amount = amount
    if (this.bolStudents.mboN3Caring.amount > maxAmount) {
      this.bolStudents.mboN3Caring.amount = maxAmount
      this.bolStudents.mboN3Other.amount = 0
      return
    }
    this.bolStudents.mboN3Other.amount = maxAmount - amount
    if (!this.bolStudents.mboN3Caring.amount) {
      this.bolStudents.mboN3Other.amount = maxAmount
    }
  }

  setBolMboN3CaringInternshipWeeks = (weeks: number) => (this.bolStudents.mboN3Caring.internshipWeeks = weeks)

  setBolMboN3CaringHours = (hours: number) => (this.bolStudents.mboN3Caring.hoursPerWeek = hours)

  setBolMboN3OtherAmount = (amount: number) => {
    const maxAmount = Math.round(this.formStore.employedBOL.level3.total)
    this.bolStudents.mboN3Other.amount = amount
    if (this.bolStudents.mboN3Other.amount > maxAmount) {
      this.bolStudents.mboN3Other.amount = maxAmount
      this.bolStudents.mboN3Caring.amount = 0
      return
    }
    this.bolStudents.mboN3Caring.amount = maxAmount - amount
    if (!this.bolStudents.mboN3Other.amount) {
      this.bolStudents.mboN3Caring.amount = maxAmount
    }
  }

  setBolMboN3OtherInternshipWeeks = (weeks: number) => (this.bolStudents.mboN3Other.internshipWeeks = weeks)
  setBolMboN3OtherHours = (hours: number) => (this.bolStudents.mboN3Other.hoursPerWeek = hours)
  setBolMboN2Amount = (amount: number) => (this.bolStudents.mboN2.amount = amount)
  setBolMboN2InternshipWeeks = (weeks: number) => (this.bolStudents.mboN2.internshipWeeks = weeks)
  setBolMboN2Hours = (hours: number) => (this.bolStudents.mboN2.hoursPerWeek = hours)
  setBolMboN1Amount = (amount: number) => (this.bolStudents.mboN1.amount = amount)
  setBolMboN1InternshipWeeks = (weeks: number) => (this.bolStudents.mboN1.internshipWeeks = weeks)
  setBolMboN1Hours = (hours: number) => (this.bolStudents.mboN1.hoursPerWeek = hours)

  setBblMeetingsSchedules = (meetingType: string | null, valueType: string | null, value: number) => {
    this.bblMeetingSchedules[meetingType as keyof MeetingSchedules][valueType as keyof MeetingObject] = value
  }

  setBolMeetingsSchedules = (meetingType: string | null, valueType: string | null, value: number) => {
    this.bolMeetingSchedules[meetingType as keyof MeetingSchedules][valueType as keyof MeetingObject] = value
  }

  setBblIntroductionPracticeInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.introduction.practiceInstructor = fraction)
  setBblIntroductionWorkInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.introduction.workInstructor = fraction)
  setBblPlanningPracticeInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.planning.practiceInstructor = fraction)
  setBblPlanningWorkInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.planning.workInstructor = fraction)
  setBblProgressPracticeInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.progress.practiceInstructor = fraction)
  setBblProgressWorkInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.progress.workInstructor = fraction)
  setBblIntermediatePracticeInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.intermediate.practiceInstructor = fraction)
  setBblIntermediateWorkInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.intermediate.workInstructor = fraction)
  setBblEndEvaluationPracticeInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.endEvaluation.practiceInstructor = fraction)
  setBblEndEvaluationWorkInstructorPresence = (fraction: number) =>
    (this.bblEmployeeAvailabilities.endEvaluation.workInstructor = fraction)

  setBolIntroductionPracticeInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.introduction.practiceInstructor = fraction)
  setBolIntroductionWorkInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.introduction.workInstructor = fraction)
  setBolPlanningPracticeInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.planning.practiceInstructor = fraction)
  setBolPlanningWorkInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.planning.workInstructor = fraction)
  setBolProgressPracticeInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.progress.practiceInstructor = fraction)
  setBolProgressWorkInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.progress.workInstructor = fraction)
  setBolIntermediatePracticeInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.intermediate.practiceInstructor = fraction)
  setBolIntermediateWorkInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.intermediate.workInstructor = fraction)
  setBolEndEvaluationPracticeInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.endEvaluation.practiceInstructor = fraction)
  setBolEndEvaluationWorkInstructorPresence = (fraction: number) =>
    (this.bolEmployeeAvailabilities.endEvaluation.workInstructor = fraction)

  isPositiveIntegerOrZero = (number: number | null | undefined) => {
    return !number || (number >= 0 && Number.isInteger(number))
  }

  isPositiveOrZero = (number: number | null | undefined) => {
    return !number || number >= 0
  }

  isPercentage(value?: number | string | null) {
    return !value || (value >= 0 && value <= 1)
  }

  get hasForm() {
    return !!this.id
  }

  get hasLocalForm() {
    if (typeof window === 'undefined') {
      return false
    }
    return !!JSON.parse(localStorage.getItem('cost-form-store') ?? 'null')?.id
  }

  get totalNumberOfBblStudents() {
    let result = 0
    for (const key of Object.keys(this.bblStudents)) {
      result += this.bblStudents[key as keyof BblStudents].amount || 0
    }
    return result
  }

  get numberOfBblFTE() {
    const WEEKS_PER_YEAR = 52
    let result = 0
    for (const key of Object.keys(this.bblStudents)) {
      result +=
        ((this.bblStudents[key as keyof BblStudents].amount || 0) *
          (this.bblStudents[key as keyof BblStudents].hoursPerWeek || 0) *
          WEEKS_PER_YEAR) /
        1280
    }
    return result
  }

  get totalSupervisonHoursPerStudentBbl() {
    let result = 0
    for (const key of Object.keys(this.bblMeetingSchedules)) {
      result +=
        ((this.bblMeetingSchedules[key as keyof MeetingSchedules].meetingDuration || 0) *
          (this.bblMeetingSchedules[key as keyof MeetingSchedules].meetingsPerStudent || 0)) /
        (this.bblMeetingSchedules[key as keyof MeetingSchedules].studentsPerMeeting || 0)
    }
    return result / 60
  }

  get totalSupervisionHoursPracticeInstructorBbl() {
    let result = 0
    for (const key of Object.keys(this.bblEmployeeAvailabilities)) {
      result +=
        (this.bblEmployeeAvailabilities[key as keyof EmployeeAvailabilities].practiceInstructor || 0) *
        (((this.bblMeetingSchedules[key as keyof MeetingSchedules].meetingDuration || 0) *
          (this.bblMeetingSchedules[key as keyof MeetingSchedules].meetingsPerStudent || 0)) /
          (this.bblMeetingSchedules[key as keyof MeetingSchedules].studentsPerMeeting || 0))
    }
    return (result / 60) * this.numberOfBblFTE
  }

  get practiceInstructorBblFTE() {
    return (
      (this.totalSupervisionHoursPracticeInstructorBbl * (1 + (this.bblPracticeInstructorIndirectWork || 0))) / 1872
    )
  }

  get totalCostsPracticeInstructorBbl() {
    return (
      this.practiceInstructorBblFTE *
      (this.practiceInstructorAverageSalary || 0) *
      (1 + (this.practiceInstructorSocialSecurity || 0)) *
      12
    )
  }

  get totalSupervisionHoursWorkInstructorBbl() {
    let result = 0
    for (const key of Object.keys(this.bblEmployeeAvailabilities)) {
      result +=
        (this.bblEmployeeAvailabilities[key as keyof EmployeeAvailabilities].workInstructor || 0) *
        (((this.bblMeetingSchedules[key as keyof MeetingSchedules].meetingDuration || 0) *
          (this.bblMeetingSchedules[key as keyof MeetingSchedules].meetingsPerStudent || 0)) /
          (this.bblMeetingSchedules[key as keyof MeetingSchedules].studentsPerMeeting || 0))
    }
    return (result / 60) * this.numberOfBblFTE
  }

  get workInstructorBblFTE() {
    return (this.totalSupervisionHoursWorkInstructorBbl * (1 + (this.bblWorkInstructorIndirectWork || 0))) / 1872
  }

  get totalCostsWorkInstructorBbl() {
    return (
      this.workInstructorBblFTE *
      (this.workInstructorAverageSalary || 0) *
      (1 + (this.workInstructorSocialSecurity || 0)) *
      12
    )
  }

  get educationCostsBbl() {
    return (
      (this.hboSubsidy || 0) * ((this.bblStudents.hboNursing.amount || 0) + (this.bblStudents.hboOther.amount || 0)) +
      (this.bblLevel34Subsidy || 0) *
        ((this.bblStudents.mboN4NursingAssistant.amount || 0) +
          (this.bblStudents.mboN4Other.amount || 0) +
          (this.bblStudents.mboN3Caring.amount || 0) +
          (this.bblStudents.mboN3Other.amount || 0)) +
      (this.bblLevel12Subsidy || 0) * ((this.bblStudents.mboN2.amount || 0) + (this.bblStudents.mboN1.amount || 0))
    )
  }

  get outOfPocketCostsBbl() {
    return (
      this.educationCostsBbl +
      (this.studyMaterialCosts || 0) * this.numberOfBblFTE +
      (this.travelCosts || 0) * this.numberOfBblFTE
    )
  }

  get hourlySalaryBbl() {
    return ((this.bblAverageSalary || 0) * (1 + (this.bblSocialSecurity || 0))) / 156
  }

  get totalCostsBblStudents() {
    return (
      (((this.totalExtraHoursPerWeek || 0) + (this.totalPaidSchoolHoursPerWeek || 0)) *
        (this.averageInternshipWeeks || 0) *
        this.numberOfBblFTE +
        this.totalSupervisonHoursPerStudentBbl * this.numberOfBblFTE) *
      this.hourlySalaryBbl
    )
  }

  get totalCostsBbl() {
    return (
      this.totalCostsBblStudents +
      this.totalCostsPracticeInstructorBbl +
      this.totalCostsWorkInstructorBbl +
      this.outOfPocketCostsBbl
    )
  }

  get internshipFundingBbl() {
    const WEEKS_PER_YEAR = 52
    let result = 0
    for (const key of Object.keys(this.bblStudents)) {
      result +=
        (((this.bblStudents[key as keyof BblStudents].amount || 0) *
          (this.bblStudents[key as keyof BblStudents].hoursPerWeek || 0) *
          WEEKS_PER_YEAR) /
          1280) *
        this.fundingPerFTEbbl[key as keyof fundingPerFTE]
    }
    return result
  }

  get subsidyIncomeBbl() {
    const WEEKS_PER_YEAR = 52
    let result = 0
    for (const key of Object.keys(this.bblStudents)) {
      result +=
        ((this.bblStudents[key as keyof BblStudents].amount || 0) *
          (this.bblStudents[key as keyof BblStudents].hoursPerWeek || 0) *
          WEEKS_PER_YEAR) /
        1280
    }
    return result * 2700
  }

  get totalRevenueBbl() {
    return this.internshipFundingBbl + this.subsidyIncomeBbl
  }

  get totalCostsMinusRevenueBbl() {
    return this.totalCostsBbl - this.totalRevenueBbl
  }

  get totalCostsMinusRevenuePerBblStudent() {
    return isFinite(this.totalCostsMinusRevenueBbl / this.numberOfBblFTE)
      ? this.totalCostsMinusRevenueBbl / this.numberOfBblFTE
      : 0
  }

  get internshipFundingBol() {
    let result = 0
    for (const key of Object.keys(this.bolStudents)) {
      result +=
        (((this.bolStudents[key as keyof BolStudents].amount || 0) *
          (this.bolStudents[key as keyof BolStudents].internshipWeeks || 0) *
          (this.bolStudents[key as keyof BolStudents].hoursPerWeek || 0)) /
          1440) *
        this.fundingPerFTEbol[key as keyof fundingPerFTE]
    }
    return result
  }

  get lessHoursRegularCareFTE() {
    return (
      (this.numberOfBolFTE * this.netSupervisionHoursPerAverageInternship * (this.availableHoursPercentage || 0)) / 1872
    )
  }

  get totalSavingsProductivity() {
    return (
      (this.bblAverageSalary || 0) *
      (1 + (this.bblSocialSecurity || 0)) *
      12 *
      this.lessHoursRegularCareFTE *
      (this.bolEmployeeProductivity || 0)
    )
  }

  get totalRevenueBol() {
    return isFinite(this.internshipFundingBol + this.totalSavingsProductivity)
      ? this.internshipFundingBol + this.totalSavingsProductivity
      : 0
  }

  get totalCostsMinusRevenueBol() {
    return isFinite(this.totalCostsBol - this.totalRevenueBol) ? this.totalCostsBol - this.totalRevenueBol : 0
  }

  get totalCostsMinusRevenuePerBolStudent() {
    return isFinite(this.totalCostsMinusRevenueBbl / this.numberOfBolFTE)
      ? this.totalCostsMinusRevenueBbl / this.numberOfBolFTE
      : 0
  }

  get totalNumberOfBolStudents() {
    let result = 0
    for (const key of Object.keys(this.bolStudents)) {
      result += this.bolStudents[key as keyof BolStudents].amount || 0
    }
    return result
  }

  get numberOfProgressMeetingsPerStudentBol() {
    let result = 0
    for (const key of Object.keys(this.bolStudents)) {
      result +=
        (this.bolStudents[key as keyof BolStudents].amount || 0) *
        (this.bolStudents[key as keyof BolStudents].internshipWeeks || 0)
    }
    return result / this.totalNumberOfBolStudents
  }

  get numberOfBolFTE() {
    let result = 0
    for (const key of Object.keys(this.bolStudents)) {
      result +=
        ((this.bolStudents[key as keyof BolStudents].amount || 0) *
          (this.bolStudents[key as keyof BolStudents].internshipWeeks || 0) *
          (this.bolStudents[key as keyof BolStudents].hoursPerWeek || 0)) /
        1440
    }
    return result
  }

  get totalCostsBolStudentsCompensation() {
    return (
      this.numberOfProgressMeetingsPerStudentBol *
      this.totalNumberOfBolStudents *
      (this.hoursNotAvailablePerWeekDueToSupervisionAndHolidayBol + this.netHoursSupervisionPerStudentPerWeek) *
      this.grossHourlyCompensationBol
    )
  }

  get grossHourlyCompensationBol() {
    return (this.bolHourlySalary || 0) * (1 + (this.bolOtherCosts || 0))
  }

  get supervisionHoursPerBolStudentPerWeek() {
    let result = 0
    for (const key of Object.keys(this.bolStudents)) {
      result +=
        ((this.bolStudents[key as keyof BolStudents].amount || 0) / (this.totalNumberOfBolStudents || 0)) *
        (this.bolStudents[key as keyof BolStudents].hoursPerWeek || 0)
    }
    return result
  }

  get minutesOfHolidayPerBPV() {
    const NUMBER_OF_HOLIDAY_WEEKS_INTERNSHIP = 2
    return this.supervisionHoursPerBolStudentPerWeek * 60 * NUMBER_OF_HOLIDAY_WEEKS_INTERNSHIP
  }

  get totalSuperVisionMinutesBol() {
    let result = 0
    for (const key of Object.keys(this.bolMeetingSchedules)) {
      result +=
        ((this.bolMeetingSchedules[key as keyof MeetingSchedules].meetingDuration || 0) *
          (this.bolMeetingSchedules[key as keyof MeetingSchedules].meetingsPerStudent || 0)) /
        (this.bolMeetingSchedules[key as keyof MeetingSchedules].studentsPerMeeting || 0)
    }
    return result
  }

  get totalMinutesSuperVisionAndHoliday() {
    return this.minutesOfHolidayPerBPV + this.totalSuperVisionMinutesBol
  }

  get hoursNotAvailablePerWeekDueToSupervisionAndHolidayBol() {
    return this.totalMinutesSuperVisionAndHoliday / 60 / this.numberOfProgressMeetingsPerStudentBol
  }

  get netHoursSupervisionPerStudentPerWeek() {
    return this.supervisionHoursPerBolStudentPerWeek - this.hoursNotAvailablePerWeekDueToSupervisionAndHolidayBol
  }

  get netSupervisionHoursPerAverageInternship() {
    return this.netHoursSupervisionPerStudentPerWeek * this.numberOfProgressMeetingsPerStudentBol
  }

  get totalSupervisionHoursWorkInstructorBol() {
    let result = 0
    for (const key of Object.keys(this.bolEmployeeAvailabilities)) {
      result +=
        (this.bolEmployeeAvailabilities[key as keyof EmployeeAvailabilities].workInstructor || 0) *
        (((this.bolMeetingSchedules[key as keyof MeetingSchedules].meetingDuration || 0) *
          (this.bolMeetingSchedules[key as keyof MeetingSchedules].meetingsPerStudent || 0)) /
          (this.bolMeetingSchedules[key as keyof MeetingSchedules].studentsPerMeeting || 0))
    }
    return (result / 60) * this.numberOfBolFTE
  }

  get workInstructorBolFTE() {
    return (this.totalSupervisionHoursWorkInstructorBol * (1 + (this.bolWorkInstructorIndirectWork || 0))) / 1872
  }

  get totalCostsWorkInstructorBol() {
    return (
      this.workInstructorBolFTE *
      (this.workInstructorAverageSalary || 0) *
      (1 + (this.workInstructorSocialSecurity || 0)) *
      12
    )
  }

  get totalSupervisionHoursPracticeInstructorBol() {
    let result = 0
    for (const key of Object.keys(this.bolEmployeeAvailabilities)) {
      result +=
        (this.bolEmployeeAvailabilities[key as keyof EmployeeAvailabilities].practiceInstructor || 0) *
        (((this.bolMeetingSchedules[key as keyof MeetingSchedules].meetingDuration || 0) *
          (this.bolMeetingSchedules[key as keyof MeetingSchedules].meetingsPerStudent || 0)) /
          (this.bolMeetingSchedules[key as keyof MeetingSchedules].studentsPerMeeting || 0))
    }
    return (result / 60) * this.numberOfBolFTE
  }

  get practiceInstructorBolFTE() {
    return (
      (this.totalSupervisionHoursPracticeInstructorBol * (1 + (this.bolPracticeInstructorIndirectWork || 0))) / 1872
    )
  }

  get totalCostsPracticeInstructorBol() {
    return (
      this.practiceInstructorBolFTE *
      (this.practiceInstructorAverageSalary || 0) *
      (1 + (this.practiceInstructorSocialSecurity || 0)) *
      12
    )
  }

  get totalCostsBol() {
    return isFinite(
      this.totalCostsBolStudentsCompensation + this.totalCostsPracticeInstructorBol + this.totalCostsWorkInstructorBol
    )
      ? this.totalCostsBolStudentsCompensation + this.totalCostsPracticeInstructorBol + this.totalCostsWorkInstructorBol
      : 0
  }

  get totalCosts() {
    return isFinite(this.totalCostsBbl + this.totalCostsBol) ? this.totalCostsBbl + this.totalCostsBol : 0
  }

  get totalRevenue() {
    return isFinite(this.totalRevenueBbl + this.totalRevenueBol) ? this.totalRevenueBbl + this.totalRevenueBol : 0
  }

  get totalCostsMinusRevenue() {
    return this.totalCosts - this.totalRevenue
  }

  get completeBblStudents() {
    for (const key of Object.keys(this.bblStudents)) {
      if (this.bblStudents[key as keyof BblStudents] === null) {
        continue
      }
      if (
        this.bblStudents[key as keyof BblStudents].amount &&
        !this.bblStudents[key as keyof BblStudents].hoursPerWeek
      ) {
        return false
      }
    }
    return true
  }

  get completeBolStudents() {
    for (const key of Object.keys(this.bolStudents)) {
      if (this.bolStudents[key as keyof BolStudents] === null) {
        continue
      }
      if (
        this.bolStudents[key as keyof BolStudents].amount &&
        (!this.bolStudents[key as keyof BolStudents].internshipWeeks ||
          !this.bolStudents[key as keyof BolStudents].hoursPerWeek)
      ) {
        return false
      }
    }
    return true
  }

  get completeBblMeetingSchedules() {
    for (const key of Object.keys(this.bblMeetingSchedules)) {
      if (
        !this.bblMeetingSchedules[key as keyof MeetingSchedules].meetingDuration ||
        !this.bblMeetingSchedules[key as keyof MeetingSchedules].studentsPerMeeting ||
        !this.bblMeetingSchedules[key as keyof MeetingSchedules].meetingsPerStudent
      ) {
        return false
      }
    }
    return true
  }

  get completeBolMeetingSchedules() {
    for (const key of Object.keys(this.bolMeetingSchedules)) {
      if (
        !this.bolMeetingSchedules[key as keyof MeetingSchedules].meetingDuration ||
        !this.bolMeetingSchedules[key as keyof MeetingSchedules].studentsPerMeeting ||
        !this.bolMeetingSchedules[key as keyof MeetingSchedules].meetingsPerStudent
      ) {
        return false
      }
    }
    return true
  }

  get completeEducationCosts() {
    return (
      this.bblLevel34Subsidy && this.bblLevel12Subsidy && this.hboSubsidy && this.studyMaterialCosts && this.travelCosts
    )
  }

  get valid() {
    return (
      this.validId &&
      this.validCurrentUrl &&
      this.validAverageInternshipWeeks &&
      this.validAverageInternshipWeeks &&
      this.validTotalExtraHoursPerWeek &&
      this.validTotalPaidSchoolHoursPerWeek &&
      this.validBblStudentsAmounts &&
      this.validBblStudentsHours &&
      this.validBolStudentsAmounts &&
      this.validBolStudentsHours &&
      this.validBolStudentsInternshipWeeks &&
      this.validBblMeetingSchedules &&
      this.validBolMeetingSchedules &&
      this.validAvailableHoursPercentage &&
      this.validBblWorkInstructorIndirectWork &&
      this.validBblPracticeInstructorIndirectWork &&
      this.validBolWorkInstructorIndirectWork &&
      this.validBolPracticeInstructorIndirectWork &&
      this.validWorkInstructorAverageSalary &&
      this.validWorkInstructorSocialSecurity &&
      this.validPracticeInstructorAverageSalary &&
      this.validPracticeInstructorSocialSecurity &&
      this.validBblLevel34Subsidy &&
      this.validBblLevel12Subsidy &&
      this.validHboSubsidy &&
      this.validStudyMaterialCosts &&
      this.validTravelCosts &&
      this.validBblAverageSalary &&
      this.validBblSocialSecurity &&
      this.validBolHourlySalary &&
      this.validBolOtherCosts &&
      this.validBolEmployeeProductivity
    )
  }

  get validId() {
    return this.id !== null
  }

  get validCurrentUrl() {
    return (
      ((this.currentUrl?.length ?? false) > 0 && (this.currentUrl?.length ?? false) <= 255) || this.currentUrl === null
    )
  }

  get validAverageInternshipWeeks() {
    return this.isPositiveIntegerOrZero(this.averageInternshipWeeks)
  }

  get validTotalExtraHoursPerWeek() {
    return this.isPositiveIntegerOrZero(this.totalExtraHoursPerWeek)
  }

  get validTotalPaidSchoolHoursPerWeek() {
    return this.isPositiveIntegerOrZero(this.totalPaidSchoolHoursPerWeek)
  }

  get validBblStudentsAmounts() {
    for (const key of Object.keys(this.bblStudents)) {
      if (this.bblStudents[key as keyof BblStudents] === null) {
        continue
      }
      if (!this.isPositiveIntegerOrZero(this.bblStudents[key as keyof BblStudents].amount)) {
        return false
      }
    }
    return true
  }

  get validBblStudentsHours() {
    for (const key of Object.keys(this.bblStudents)) {
      if (this.bblStudents[key as keyof BblStudents] === null) {
        continue
      }
      if (!this.isPositiveIntegerOrZero(this.bblStudents[key as keyof BblStudents].hoursPerWeek)) {
        return false
      }
    }
    return true
  }

  get validBolStudentsAmounts() {
    for (const key of Object.keys(this.bolStudents)) {
      if (this.bolStudents[key as keyof BolStudents] === null) {
        continue
      }
      if (!this.isPositiveIntegerOrZero(this.bolStudents[key as keyof BolStudents].amount)) {
        return false
      }
    }
    return true
  }

  get validBolStudentsInternshipWeeks() {
    for (const key of Object.keys(this.bolStudents)) {
      if (this.bolStudents[key as keyof BolStudents] === null) {
        continue
      }
      if (!this.isPositiveIntegerOrZero(this.bolStudents[key as keyof BolStudents].internshipWeeks)) {
        return false
      }
    }
    return true
  }

  get validBolStudentsHours() {
    for (const key of Object.keys(this.bolStudents)) {
      if (this.bolStudents[key as keyof BolStudents] === null) {
        continue
      }
      if (!this.isPositiveIntegerOrZero(this.bolStudents[key as keyof BolStudents].hoursPerWeek)) {
        return false
      }
    }
    return true
  }

  get validBblMeetingSchedules() {
    for (const meetingType of Object.keys(this.bblMeetingSchedules)) {
      for (const valueType of Object.keys(this.bblMeetingSchedules[meetingType as keyof MeetingSchedules])) {
        if (this.bblMeetingSchedules[meetingType as keyof MeetingSchedules] == null) {
          continue
        }
        if (
          !this.isPositiveIntegerOrZero(
            this.bblMeetingSchedules[meetingType as keyof MeetingSchedules][valueType as keyof MeetingObject]
          )
        ) {
          return false
        }
      }
    }
    return true
  }

  get validBolMeetingSchedules() {
    for (const meetingType of Object.keys(this.bolMeetingSchedules)) {
      for (const valueType of Object.keys(this.bolMeetingSchedules[meetingType as keyof MeetingSchedules])) {
        if (
          this.bolMeetingSchedules[meetingType as keyof MeetingSchedules] == null ||
          (meetingType === 'progress' && valueType === 'meetingsPerStudent')
        ) {
          continue
        }
        if (
          !this.isPositiveIntegerOrZero(
            this.bolMeetingSchedules[meetingType as keyof MeetingSchedules][valueType as keyof MeetingObject]
          )
        ) {
          return false
        }
      }
    }
    return true
  }

  get validAvailableHoursPercentage() {
    return this.isPercentage(this.availableHoursPercentage)
  }

  get validBblWorkInstructorIndirectWork() {
    return this.isPercentage(this.bblWorkInstructorIndirectWork)
  }

  get validBblPracticeInstructorIndirectWork() {
    return this.isPercentage(this.bblPracticeInstructorIndirectWork)
  }

  get validBolWorkInstructorIndirectWork() {
    return this.isPercentage(this.bolWorkInstructorIndirectWork)
  }

  get validBolPracticeInstructorIndirectWork() {
    return this.isPercentage(this.bolPracticeInstructorIndirectWork)
  }

  get validWorkInstructorAverageSalary() {
    return this.isPositiveOrZero(this.workInstructorAverageSalary)
  }

  get validWorkInstructorSocialSecurity() {
    return this.isPercentage(this.workInstructorSocialSecurity)
  }

  get validPracticeInstructorAverageSalary() {
    return this.isPositiveOrZero(this.practiceInstructorAverageSalary)
  }

  get validPracticeInstructorSocialSecurity() {
    return this.isPercentage(this.practiceInstructorSocialSecurity)
  }

  get validBblLevel34Subsidy() {
    return this.isPositiveOrZero(this.bblLevel34Subsidy)
  }

  get validBblLevel12Subsidy() {
    return this.isPositiveOrZero(this.bblLevel12Subsidy)
  }

  get validHboSubsidy() {
    return this.isPositiveOrZero(this.hboSubsidy)
  }

  get validStudyMaterialCosts() {
    return this.isPositiveOrZero(this.studyMaterialCosts)
  }

  get validTravelCosts() {
    return this.isPositiveOrZero(this.travelCosts)
  }

  get validEducationCosts() {
    return (
      this.validBblLevel34Subsidy &&
      this.validBblLevel12Subsidy &&
      this.validHboSubsidy &&
      this.validTravelCosts &&
      this.validStudyMaterialCosts
    )
  }

  get validBblAverageSalary() {
    return this.isPositiveOrZero(this.bblAverageSalary)
  }

  get validBblSocialSecurity() {
    return this.isPercentage(this.bblSocialSecurity)
  }

  get validBolHourlySalary() {
    return this.isPositiveOrZero(this.bolHourlySalary)
  }

  get validBolOtherCosts() {
    return this.isPercentage(this.bolOtherCosts)
  }

  get validBolEmployeeProductivity() {
    return this.isPercentage(this.bolEmployeeProductivity)
  }
}
