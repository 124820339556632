import useTranslation from 'next-translate/useTranslation'
import { StyledButton } from './NavButton'

interface CancelButton {
  onClick: () => void
  size: 'small' | 'medium' | 'large'
}

export const CancelButton = ({ onClick, size }: CancelButton) => {
  const { t } = useTranslation('common')
  return (
    <StyledButton size={size} action='back' onClick={onClick}>
      {t('buttons.cancel')}
    </StyledButton>
  )
}
