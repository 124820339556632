import { styled } from '@styles/config'
import { useRouter } from 'next/router'

export const StyledButton = styled('button', {
  display: 'flex',
  gap: '10px',
  padding: '12px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'none',
  cursor: 'pointer',
  border: 'none',
  height: '44px',
  fontSize: '$medium',
  fontWeight: '600',

  '&:disabled': {
    opacity: '50%',
    cursor: 'default',
  },

  variants: {
    size: {
      small: {
        width: '100px',
      },
      medium: {
        width: '272px',
      },
      large: {
        width: '384px',
      },
    },
    action: {
      primary: {
        backgroundColor: '$primaryPurple',
        color: '$white',
      },
      secondary: {
        border: '1px solid $secondaryPurple',
        backgroundColor: '$white',
        color: '$secondaryPurple',
      },
      back: {
        backgroundColor: '$gray400',
        color: '$gray800',
      },
    },
  },
})

interface NavButton {
  href: string
  text: string
  size: 'small' | 'medium' | 'large'
  action: 'primary' | 'secondary' | 'back'
  icon?: React.ReactElement
  disabled?: boolean
  newTab?: boolean
}

export const NavButton = ({ href, text, size, action, disabled, icon, newTab }: NavButton) => {
  const router = useRouter()
  return (
    <StyledButton
      size={size}
      action={action}
      disabled={disabled}
      onClick={() => {
        if (newTab) {
          window.open(href, '_blank')
        } else {
          router.push(href)
        }
      }}
    >
      <p>{text}</p>
      {icon}
    </StyledButton>
  )
}
