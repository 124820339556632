import { styled } from '@styles/config'
import { observer } from 'mobx-react'
import { Card } from '@/Card/Card'
import React from 'react'

export const NavContainer = styled(Card, {
  height: '690px',
  width: '280px',
  padding: '0 24px 30px 24px',
  overflow: 'auto',
})

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxHeight: '800px',
})

export const StepTitle = styled('span', {
  margin: '25px 0 0 0',
  fontWeight: '600',
})

interface Sidenav {
  children: React.ReactNode
}

export const Sidenav = observer(({ children }: Sidenav) => {
  return <Wrapper>{children}</Wrapper>
})
