import { FormStore } from './formStore'
import { CostStore } from './costStore'
import { AuthStore } from './authStore'
import { VisitedStore } from '@stores/visitedStore'

export class RootStore {
  authStore: AuthStore
  visitedStore: VisitedStore
  formStore: FormStore
  costStore: CostStore

  constructor() {
    this.authStore = new AuthStore(this)
    this.visitedStore = new VisitedStore(this)
    this.formStore = new FormStore(this)
    this.costStore = new CostStore(this)
  }
}
