import { useStore } from '@stores/index'
import useTranslation from 'next-translate/useTranslation'
import { MoveModal } from '@/Modal/MoveModal'
import router from "next/router"

export const MoveInflowCalculationModal = () => {
  const { t } = useTranslation('common')
  const { formStore } = useStore()

  const wipe = async () => {
    await formStore.removeLocal()
    formStore.setMoveCalculationModalIsOpen()
    router.push('/inflow-calculation/organisation-details/name')
  }

  const move = async () => {
    await formStore.moveLocal()
    formStore.setMoveCalculationModalIsOpen()
    router.push(formStore.currentUrl || '/inflow-calculation/organisation-details/name')
  }

  return (
    <MoveModal
      wipe={wipe}
      move={move}
      close={formStore.setMoveCalculationModalIsOpen}
      title={t('modal.move-calculation-confirmation-title')}
      text={t('modal.move-calculation-warning')}
    ></MoveModal>
  )
}
